import React, { useEffect, useRef, useState } from "react";
import "./authModal.scss";
import { connect } from "react-redux";
import { setLoggedIn, setUserToken, setUserId } from "../../redux/Functions/actions";
import axios from "../../axios";
import { toast } from "react-toastify"
import sign from "jwt-encode";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPasswordModal = (props) => {



    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState(false);


    const secretKey = process.env.REACT_APP_SECRET_KEY
    const params = useLocation()
    const navigate = useNavigate()

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (password === confirmPassword) {
            const body = {
                password: sign(password, secretKey),
            }
            axios
                .post(`/api/auth/reset_password/change-password/${params.pathname.split('/')[2]}`, body)
                .then((response) => {
                    toast.success(response.data.message)
                    navigate('/')
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message);
                    console.log('error:', error);
                });
        }
        else {
            toast.error("Fjalëkalimet duhet të jenë të njejta!")
        }
    };

    return (
        <div className="modal-layout">
            <div className="modal-container" data-aos="flip-right">
                <div className="modal-title">
                    <h3>Ndryshoni Fjalëkalimin</h3>
                </div>
                <form onSubmit={(e) => handleChangePassword(e)}>
                    <div className="input-container">
                        <p>Shëno fjalëkalimin e ri: </p>
                        <div className="inner-input-container">
                            <span className="lock_icon"></span>
                            <input
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                name="Password"
                                placeholder="Fjalëkalimi ri"
                                className="auth-form-input"
                                required
                            />
                        </div>
                    </div>

                    <div className="input-container">
                        <p>Konfirmo fjalëkalimin: </p>
                        <div className="inner-input-container">
                            <span className="lock_icon"></span>
                            <input
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type="password"
                                name="confirmPassword"
                                placeholder="Konfirmo fjalëkalimin"
                                className="auth-form-input"
                                required
                            />
                        </div>
                    </div>
                    <button type="submit" className="auth-btn">
                        Ndrysho
                    </button>
                </form>
            </div>
        </div>
    );
};



// in order to get data from redux you need to call their value from te store of redux by imporijng it and by using connect from redux we pass all of that data that we imported as props to our component and-or page ! 
const mapStateToProps = (state) => {
    return {
        // selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        username: state.data.username,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLoggedIn: (data) => dispatch(setLoggedIn(data)),
        setUserToken: (token) => dispatch(setUserToken(token)),
        setUserId: (data) => dispatch(setUserId(data)),
        // setUserName: (data) => dispatch(setUserName(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal)
