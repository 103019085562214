import React from 'react'
import FacebookLogo from "../../assets/images/Facebook.png"
import InstagramLogo from "../../assets/images/Instagram.png"
import TikTokLogo from "../../assets/images/tiktok-icon.png"
import YoutubeLogo from "../../assets/images/youtube-icon.png"
import { HiUserCircle } from "react-icons/hi2"
import { AiOutlineLogout } from "react-icons/ai"
// import { Link } from 'react-router-dom'
import Modal from './authModal'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import TranslateLabel from '../translations/labels'


// import DefaultProfilePic from "../../assetes/images/user-solid.svg"
// import ButtonStyle from "./buttonGroupStyle"

export const SocialMediaLinks = (props) => {
    return (
        <div className="social-medias d-flex" style={props?.style !== null ? { ...props?.style } : ""}>
            <a href="https://www.facebook.com/termini.beauty" target='blank'> <img src={FacebookLogo} alt='' /></a>
            <a href="https://www.instagram.com/termini.beauty" target='blank'> <img src={InstagramLogo} alt='' /></a>
            <a href="https://www.youtube.com/@TerminiBeauty" target='blank'> <img src={YoutubeLogo} alt='' /></a>
            <a href="https://www.tiktok.com/@termini.beauty?_t=8ij93FFqztG&_r=1" target='blank'> <img src={TikTokLogo} alt='' /></a>
        </div>
    )
}

export const LoginButton = () => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className="login d-flex align-items-center mx-2">
            <HiUserCircle className='me-2' />
            <span className='openModalBtn'
                onClick={() => {
                    setOpenModal(true);

                }}
            >{TranslateLabel("Kyçu")}</span>
            {openModal && <Modal closeModal={() => setOpenModal(!openModal)} />}
        </div>
    )
}
export const RegisterButton = () => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className="login d-flex align-items-center mx-2">
            <HiUserCircle className='me-2' />
            <span className='openModalBtn'
                onClick={() => {
                    setOpenModal(true);

                }}
            >{TranslateLabel("Regjistrohu")}</span>
            {openModal && <Modal shouldShowSignUp={true} closeModal={() => setOpenModal(!openModal)} />}
        </div>
    )
}

export const LogOutButton = ({ onClick }) => {
    return (
        <div className='logout d-flex align-items-center mx-2' onClick={onClick}>
            <AiOutlineLogout className='me-2' />
            <span>Dil</span>
        </div>
    )
}

export const ProfileButton = () => {
    return (
        <Link to="/profile">
            <div className='profile d-flex align-items-center mx-2'>
                <HiUserCircle className='me-2' />
                <span>Profili</span>
            </div>
        </Link>
    )
}

export const BusinessButton = () => {
    return (
        <a href="https://store.termini.beauty/" target="blank" className='business-button d-flex align-items-center mx-2'>
            <div className='business-icon me-2'></div>
            <span>{TranslateLabel("Unë jam një biznes")}</span>
        </a>
    )
}


export const CheckoutButton = () => {
    return (
        <div className="checkout-btn d-flex justify-content-end mt-4">
            <button style={{ backgroundColor: '#ff5758', borderRadius: '10px', color: 'white', padding: '3px 30px', border: 'none' }}>
                <span style={{ fontSize: '18px' }}>{TranslateLabel("CaktoTerminin")}</span>
            </button>
        </div>
    );
};

export const ChooseButton = () => {
    return (
        <>
            <button className="choose-btn">Zgjedh</button>
        </>
    )
}


export const SigninButton = () => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className="signin d-flex align-items-center mx-2">
            <span className='openModalBtn'
                onClick={() => {
                    setOpenModal(true);

                }}
            >{TranslateLabel("Kyçu")}</span>
            {openModal && <Modal closeModal={() => setOpenModal(!openModal)} />}
        </div>
    )
}
