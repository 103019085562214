import React, { useRef, useEffect } from "react";

const GoogleMap = ({ location, height, width }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: location,
      zoom: 15,
    });

    const marker = new window.google.maps.Marker({
      position: location,
      map,
      title: "Salon Location",
    });
    
  }, [location]);

  const mapStyle = {
    height: height,
    width: width,
  };

  return <div ref={mapRef} style={mapStyle} />;
};

export default GoogleMap;
