import axios from "axios";
import Cookies from 'js-cookie';
const defaultInstance = axios.create({
    baseURL: process.env.REACT_APP_API,
})
defaultInstance.interceptors.request.use(
    config => {
        const token = window.localStorage.getItem('termini_im');
        if (token) { 
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


export default defaultInstance;