import React, { useEffect, useRef, useState } from "react";
import "./authModal.scss";
import { connect } from "react-redux";
import { setLoggedIn, setUserToken, setUserId } from "../../redux/Functions/actions";
import axios from "../../axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify"
import sign from "jwt-encode";
import { Link } from "react-router-dom";

const Modal = (props) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY

  const [showSignUp, setShowSignUp] = useState(props?.shouldShowSignUp);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);

  // console.log("forgotPassword", forgotPassword)



  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        props?.closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props?.closeModal]);

  // const handleLogin = () => {

  // };

  const handleSignUpClick = () => {
    setShowSignUp(true);
  };

  const handleBackToLoginClick = () => {
    setShowSignUp(false);
  };

  // useEffect(() => {
  //   axios
  //     .get(`/admin/users/single/58`)
  //     .then((response) => {
  //       console.log('singleuser res', response)
  //     })
  //     .catch((error) => {
  //       toast.error("Authentication failed!");
  //       console.log('error:', error);
  //     });

  // }, [])

  // console.log('secret',sign(1234, secretKey))

  const handleModalClose = () => {
    props?.closeModal(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const body = {
      email: email,
      password: sign(password, secretKey)
    }
    axios
      .post("/api/auth/login", body)
      .then((response) => {
        if (response.status === 200) {
          const decodeToken = jwtDecode(response.data.token, secretKey)
        
          if (decodeToken.role !== 5) {
            toast.warning("Ju nuk keni qasje në platformën për klient!");
          } else {
            props?.setUserToken(response.data.token)
            window.localStorage.setItem('termini_im', response.data.token);
            props?.setLoggedIn(true)
            // props.setUserRole(decodeToken.role)
            // props?.setSalonId(decodeToken?.salon)
            props?.setUserId(decodeToken?.user_id)
            handleModalClose();
            toast.success("Jeni kyqur me sukses!");
          }
        }
      })
      .catch((error) => {
        toast.error("Autentifikimi dështoi!");
        console.log('error:', error);
      });
  };


  const handleResetPassword = (e) => {
    e.preventDefault();
    const body = {
      email: email,
    }
    axios
      .post("/api/auth/reset_password/generate-link", body)
      .then((response) => {
        // console.log('response', response)
        toast.success(response.data.message)
        // handleModalClose();
      })
      .catch((error) => {
        toast.error("Autentifikimi dështoi!");
        console.log('error:', error);
      });
  };
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [phoneNumber, setPhoneNumber] = useState()

  const handleRegister = (e) => {
    e.preventDefault();
    const body = {
      role_id: 5,
      name: firstName,
      surname: lastName,
      email: email,
      username: username,
      phone_number: phoneNumber,
      password: sign(password, secretKey)
    }
    axios
      .post("/api/auth/signup", body)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Regjistri u krye me sukses!");
          try {
            // console.log("response?.data", response?.data)
            const body = {
              email: response?.data?.newUserData?.email,
              password: sign(password, secretKey)
            }
            axios
              .post("/api/auth/login", body)
              .then((response) => {
                if (response.status === 200) {
                  const decodeToken = jwtDecode(response.data.token, secretKey)
                  props.setLoggedIn(true)
                  window.localStorage.setItem('termini_im', response.data.token, { expires: 1 });
                  props?.setUserToken(response.data.token)
                  props.setUserId(decodeToken.user_id)
                  props.setLoggedIn(true);
                  toast.success("Jeni kyqur me sukses!");
                  handleModalClose();
                }
              })
              .catch((error) => {
                toast.error("Autentifikimi dështoi!");
                console.log('error:', error);
              });

          } catch (err) {
            toast.error(err)
          }
        }
      })
      .catch((error) => {

        if (error.response && error.response.data) {
          toast.error(error.response.data);
        } else {

          toast.error("Ndodhi një gabim gjatë regjistrimit.");
        }
        console.log('error:', error);
      });
  };


  // const logOut = () => {
  //   props.loggMeIN();
  //   props.rmAuth();
  //   window.localStorage.clear();
  //   window.location.assign('/');
  //   props.setActiveMenu(1);

  // }

  return (
    <div className="modal-layout">
      <div ref={modalRef} className="modal-container" data-aos="flip-right">
        <div className="close-btn-signup">
          <button onClick={() => props?.closeModal(false)}>&times;</button>
        </div>
        <div className="modal-title">
          {showSignUp ? (
            <h3>Regjistrohu</h3>
          ) : (
            <h3>{!forgotPassword ? "Kyqu" : "Rikthe passwordin"}</h3>
          )}
          {showSignUp ? (
            <p>Veçse keni një llogari ? <a onClick={handleBackToLoginClick}>Kyqu</a></p>
          ) : (
            <p>I ri tek Termini.Beauty? <a onClick={handleSignUpClick}>Regjistrohu</a></p>
          )}
        </div>
        <form onSubmit={showSignUp ? handleRegister : !forgotPassword ? handleLogin : handleResetPassword} >
          <div className="input-container">
            {showSignUp && (
              <>
                <div className="input-container">
                  <p>Emri: </p>
                  <div className="inner-input-container">
                    <span className="user-icon"></span>
                    <input type="text" name="name" placeholder="Shkruani emrin tuaj" onChange={(e) => setFirstName(e.target.value)} className="auth-form-iinput" required />
                  </div>
                </div>
                <div className="input-container">
                  <p>Mbiemri: </p>
                  <div className="inner-input-container">
                    <span className="user-icon"></span>
                    <input type="text" name="name" placeholder="Shkruani mbiemrin tuaj" onChange={(e) => setLastName(e.target.value)} className="auth-form-iinput" required />
                  </div>
                </div>
                <div className="input-container">
                  <p>Numri telefonit: </p>
                  <div className="inner-input-container">
                    <span className="phone-icon"></span>
                    <input type="number" name="name" placeholder="Shkruani numrin e telefonit" onChange={(e) => setPhoneNumber(String(e.target.value))} className="auth-form-iinput" required />
                  </div>
                </div>
              </>

            )}
            <p>Email Adresa: </p>
            <div className="inner-input-container">
              <span className="email-icon"></span>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type={showSignUp ? "email" : "text"}
                name="email"
                placeholder="Shkruani email adresen tuaj "
                className="auth-form-input"
                required
              />
            </div>

            {/* <p>Username: </p>
            <div className="inner-input-container">
              <span className="user-icon"></span>
              <input
                onChange={(e) => setUsername(e.target.value)}
                type="username"
                name="username"
                placeholder="Shkruani username-in tuaj"
                className="auth-form-input"
                required
                autocomplete="new-username"
              />
            </div> */}

            {showSignUp &&
              <div className="input-container">
                <p>Username: </p>
                <div className="inner-input-container">
                  <span className="user-icon"></span>
                  <input type="text" name="name" placeholder="Shkruani emrin e përdoruesit" onChange={(e) => setUsername(e.target.value)} className="auth-form-iinput" required />
                </div>
              </div>
            }
          </div>
          {!forgotPassword && <div className="input-container">
            <p>Fjalëkalimi: </p>
            <div className="inner-input-container">
              <span className="lock_icon"></span>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                placeholder="Shkruani fjalëkalimin"
                className="auth-form-input"
                required
              />
            </div>
          </div>}
          {showSignUp && (
            <div className="input-container">
              <p>Konfirmo fjalëkalimin: </p>
              <div className="inner-input-container">
                <span className="lock_icon"></span>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Konfirmo fjalëkalimin"
                  className="auth-form-input"
                  required
                />
              </div>
            </div>
          )}
          {showSignUp && <>
            <label htmlFor="termatDheKushtet">  <input type="checkbox" id="termatDheKushtet" required /> Pranoj <Link onDoubleClick={() => props?.closeModal(false)} to="/terms-and-conditions"> <label htmlFor="differentCheckboxId">termat dhe kushtet</label></Link></label>
          </>
          }
          <button type="submit" className="auth-btn">
            {showSignUp ? "Regjistrohu" : !forgotPassword ? "Kyqu" : "Ndrysho passwordin"}
          </button>
        </form>
        <p className="text-center mt-3" role="button" onClick={() => { setForgotPassword(!forgotPassword) }}> {!forgotPassword ? "Keni Harruar Fjalëkalimin?" : !showSignUp && "Kyquni"}</p>
      </div>
    </div>
  );
};



// in order to get data from redux you need to call their value from te store of redux by imporijng it and by using connect from redux we pass all of that data that we imported as props to our component and-or page ! 
const mapStateToProps = (state) => {
  return {
    // selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    username: state.data.username,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    setUserToken: (token) => dispatch(setUserToken(token)),
    setUserId: (data) => dispatch(setUserId(data)),
    // setUserName: (data) => dispatch(setUserName(data)),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Modal)
