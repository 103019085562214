import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./homepage.scss";
import { connect } from "react-redux";
import axios from "../../axios";
import { ImArrowRight } from 'react-icons/im'
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import salonsToMap from "./homepageSalons.json";
import { Slide } from "react-slideshow-image";
import { Lightbox } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import TranslateLabel from "../../components/translations/labels";

const Homepage = (props) => {

  const searchAreaRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const [showTreatmentDropdown, setShowTreatmentDropdown] = useState(false);
  const [showStaffDropdown, setShowStaffDropdown] = useState(false);
  const [showSalonLocationDropdown, setShowSalonLocationDropdown] = useState(false);
  const [activeInput, setActiveInput] = useState("");


  const [selectedName, setSelectedName] = useState("");
  const [selectedTreatment, setSelectedTreatment] = useState([]);
  const [searchLocationQuery, setSearchLocationQuery] = useState("");
  const [selectedStaff, setSelectedStaff] = useState("");

  const [searchTreatmentQuery, setSearchTreatmentQuery] = useState("")
  const [filteredDataByTreatment, setFilterDataByTreatment] = useState([])
  const [searchLocation, setSearchLocation] = useState([])

  const filterData = (filterFn) => selectedTreatment?.filter(filterFn);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleSearchTreatment = (event) => {
    setTreatmentSearchName(event.target.value)
    setSearchTreatmentQuery(event.target.value);
    axios.get(`/api/homepage/treatment/all?search=${event.target.value}`,)
      .then(res => {
        setSelectedTreatment(res.data.data)
        setFilterDataByTreatment(res.data.data)
        setShowTreatmentDropdown(event.target.value.length > 0);
        setShowDropdown(event.target.value.length > 0);
        setActiveInput("searchTreatment");
      })
      .catch(err => {
        console.log("error ", err)
      })

  };
  const [salonLocationQuery, setSalonLocationQuery] = useState("");
  const [salonLocation, setSalonLocation] = useState("");

  const handleSalonLocationLocation = (event) => {
    setSalonLocationQuery(event.target.value);
    setTreatmentLocationValue(event.target.value);
    axios.get(`/api/cities/all`)
      .then(res => {
        setSalonLocation(res.data)
        setShowLocationDropdown(event.target.value);
        setShowDropdown(event.target.value.length > 0);
        setActiveInput("selectedLocation");
      })
      .catch(err => {
        console.log("error", err)

      })
  };
  const [filterDataByLocation, setFilterDataByLocation] = useState()
  const handleSelectedLocation = (event) => {
    setSearchLocationQuery(event.target.value);
    setTreatmentLocationValue(event.target.value);
    axios.get(`/api/cities/all`)
      .then(res => {
        setSearchLocation(res.data)
        setFilterDataByLocation(res.data)
        setShowLocationDropdown(event.target.value);
        setShowDropdown(event.target.value.length > 0);
        setActiveInput("selectedLocation");
      })
      .catch(err => {
        console.log("error", err)
      })
  };


  // --------------------------------------------------------------- // 
  const [selectedSalon, setSelectedSalon] = useState("")

  const handleSelectedName = (event) => {
    setSelectedName(event.target.value);
    setSearchSalonName(event.target.value);
    axios.get(`/api/homepage/salon/all?name=${event.target.value}`)
      .then(res => {
        setSelectedSalon(res.data.data)
        setShowDropdown(event.target.value.length > 0);
        setActiveInput("selectedName");
      })
      .catch(err => {
        console.log("error", err)
      })

  };
  // --------------------------------------------------------------- // 
  const [selectedSalonStaff, setSelectedSalonStaff] = useState()
  const [selectedStaffName, setSelectedStaffName] = useState()

  // const handleSelectedStaff = (event) => {
  //   setSelectedStaff(event.target.value);
  //   axios.get(`/api/homepage/salon/all?stafi=${event.target.value}`)
  //     .then(res => {
  //       setSelectedSalonStaff(res.data.data)
  //       setSelectedStaffName(event.target.value)
  //       setShowStaffDropdown(event.target.value.length > 0);
  //       setShowDropdown(event.target.value.length > 0);
  //       setActiveInput("selectedStaff");
  //     })
  //     .catch(err => {
  //       console.log("error", err)
  //     })
  // };

  const handleSelectedStaff = () => {
    axios.get(`/api/homepage/salon/all?stafi=${selectedStaffName}`)
      .then(res => {
        setSelectedSalonStaff(res.data.data);
        setShowStaffDropdown(selectedStaffName.length > 0);
        setShowDropdown(selectedStaffName.length > 0);
        setActiveInput("selectedStaff");
        // setSelectedStaffName("");
      })
      .catch(err => {
        console.log("error", err);
      });
  };

  const handleSelectedStaffInputChange = (event) => {
    setSelectedStaffName(event.target.value);
    setActiveInput("selectedStaff");

    axios.get(`/api/homepage/salon/all?stafi=${event.target.value}`)
      .then(res => {
        setSelectedSalonStaff(res.data.data);
        setShowStaffDropdown("selectedStaffName.length > 0");
        setShowDropdown(selectedStaffName.length > 0);
        // setSelectedStaffName("");
      })
      .catch(err => {
        console.log("error", err);
      });
  };


  // const test = document.addEventListener("mousedown", handleClickOutside);
  const [searchSalonName, setSearchSalonName] = useState()
  const renderSalonNameDropdown = (data, limit) => {
    return (
      <div className="dropdown-wrapper">
        <div className="dropdown">
          <ul >
            {/* /api/search/all?search=floke */}
            {data?.slice(0, limit).map((value) => (
              <li
                role="button"
                onClick={() => {
                  setSearchSalonName(value?.name_al);
                  setActiveInput("")
                }
                  // window.location.assign(`/searchResult/${searchTreatmentQuery}`)
                } key={value?.salon?.id}>{value?.name_al}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };




  // const renderDropdown = (data, limit) => {
  //   const flattenedSubSubServices = [];
  //   return (
  //     <div className="dropdown-wrapper">
  //       <div className="dropdown">
  //         <ul >
  //           {/* /api/search/all?search=floke */}
  //           {data?.map((value) => (
  //             <li onClick={() => { setSearchSalonName(value.name_al); setShowDropdown(false) }} key={value?.salon?.id}>{value?.name_al}</li>
  //           ))}
  //         </ul>
  //       </div>
  //     </div>
  //   );
  // };



  const [treatmentSearchName, setTreatmentSearchName] = useState()
  const renderDropdown = (data, limit) => {
    // filteredDataByTreatment?.reduce((acc, obj) => acc.concat(obj?.sub_services?.reduce((acc2, obj2) => acc2.concat(obj2?.sub_sub_services),[])), [])
    let arrayOfArrays = data?.reduce((acc, obj) => acc.concat(obj?.sub_services?.reduce((acc2, obj2) => acc2.concat(obj2?.sub_sub_services), [])), [])
    // const concatenatedArray = []?.concat(...arrayOfArrays[0]);


    return (
      <div className="dropdown-wrapper">
        <div className="dropdown">

          <ul >
            {arrayOfArrays?.map(el =>
              <li role="button" onClick={() => { setTreatmentSearchName(props?.selected_lang === "AL" ? el.name_al : el?.name_en); setActiveInput("") }} key={el?.id}>{props?.selected_lang === "AL" ? el?.name_al : el?.name_en}</li>
            )}
            {data?.length > limit && <li>...</li>}
          </ul>
        </div>
      </div>
    );
  };
  const [treatmentLocationValue, setTreatmentLocationValue] = useState()
  const renderLocationDropdown = (data, limit) => {
    const filteredData = data?.filter((el) => el?.name.toLowerCase().includes(showLocationDropdown.toLowerCase()));

    // console.log('test, te', filteredData)
    return (
      <div className="dropdown-wrapper">
        <div className="dropdown">
          <ul >
            {/* {console.log("data", data)} */}
            {filteredData?.map((el) => (
              <li role="button" onClick={(e) => { setTreatmentLocationValue(el?.name); setActiveInput("") }} key={el?.id}>{el?.name} </li>
            ))}

          </ul>
        </div>
      </div>
    );
  };
  const renderTreatmentLocationDropdown = (data, limit) => {
    const filteredData = data?.filter((el) => el?.name.toLowerCase().includes(showLocationDropdown.toLowerCase()));
    // console.log('test, te', filteredData)
    return (
      <div className="dropdown-wrapper">
        <div className="dropdown">
          <ul >
            {/* {console.log("data", data)} */}
            {filteredData?.map((el) => (
              <li role="button" onClick={(e) => { setTreatmentLocationValue(el?.name); setActiveInput("") }} key={el?.id}>{el?.name} </li>
            ))}

          </ul>
        </div>
      </div>
    );
  };
  const [salonLocationValue, setSalonLocationValue] = useState()
  // const renderSalonLocationDropdown = (data, limit) => {
  //   return (
  //     <div className="dropdown-wrapper">
  //       <div className="dropdown">
  //         <ul >
  //           {data?.map((value) => {
  //             return (
  //               <li onClick={(e) => { setSalonLocationValue(value?.name_al + " - " + value.street_address_al); setShowLocationDropdown(false) }} key={value?.id}>{value?.name_al} - {value.street_address_al}   </li>
  //             )
  //           })}
  //           {data?.length > limit && <li>...</li>}
  //         </ul>
  //       </div>
  //     </div>
  //   );
  // };


  // const renderStafDropdown = (data, limit) => {
  //   return (
  //     <div className="dropdown-wrapper">
  //       <div className="dropdown">
  //         <ul >
  //           {data?.map(el => el.salon.users.map(el => <li>{el.name}</li>))}
  //         </ul>
  //       </div>
  //     </div>
  //   );
  // };

  const renderStafDropdown = (data, limit) => {
    // const searchQuery = "";
    // const filteredData = data?.filter(el => el.salon.users.some(user => user.name.includes(searchQuery)));
    return (
      <div className="dropdown-wrapper">
        <div className="dropdown">
          <ul>
            {data?.length > 0 ? (
              data?.map(el => <li role="button" onClick={() => { setSelectedStaffName(el.name); setActiveInput("") }}>{el.name}</li>
              )) : (
              <li>Nuk ka te dhena</li>
            )}
          </ul>
        </div>
      </div>
    );
  };



  const handleSearchPageNav = () => {
    searchTreatmentQuery !== "" ?
      window.location.assign(`/searchResult/searchData/all${searchTreatmentQuery !== "" ? `?treatment=${searchTreatmentQuery}&` : ""}${searchLocationQuery !== "" ? `${searchTreatmentQuery == "" ? "?" : ""}treatmentLocation=${searchLocationQuery}` : ""}`)
      : toast.warning("Ju lutem shenoni emrin e nje tretmani qe deshironi te kerkoni ")
  }

  const handleSalonPageNav = () => {
    // window.location.assign(`/searchResult/searchData/all?`)
    //   `/all${selectedName ? `?salonName=${selectedName}` : ''}
    // ${salonLocationQuery ? `${!selectedName ? '?' : '&'}salonAddress=${salonLocationQuery}` : ''}
    // ${selectedStaffName ? `${!selectedName ? '?' : '&'}staff=${selectedStaffName}` : ''}
    // `
    // )
    if (!selectedName && !salonLocationQuery == false) {

      toast.error("Ju lutem shenoni emrin e sallonit dhe the lokacionit qe deshironi te kerkoni!")
    } else {
      // toast.success('hini')
      window.location.assign(`/searchResult/searchData/all${selectedName ? `?salonName=${selectedName}` : ''}${salonLocationQuery ? `${!selectedName ? '?' : '&'}salonAddress=${salonLocationQuery}` : ''}${selectedStaffName ? `${!selectedName ? '?' : '&'}staff=${selectedStaffName}` : ''}`)
    }

  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (
      searchAreaRef.current &&
      !searchAreaRef.current.contains(event.target) &&
      event.target.nodeName !== "INPUT"
    ) {
      setActiveInput("");
    }
  };

  const handleInputFocus = () => {
    setActiveInput("");
    setShowDropdown(false);
  };

  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideClick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };
  return (
    <>

      <div className="homepage-layout pb-5 ">
        <div className="homepage-banner">
          <div className="info" data-aos="flip-left">
            <div className="tabs-container">
              <Tabs selectedIndex={selectedTabIndex} onSelect={index => setSelectedTabIndex(index)}>
                <TabList>
                  <Tab>
                    <div className="tab-wrapper">
                      <div className="icon icon1"></div>
                      <h5>{TranslateLabel("Tretmani")}</h5>
                    </div>
                  </Tab>
                  <Tab>
                    <div className="tab-wrapper">
                      <div className="icon icon2"></div>
                      <h5>{TranslateLabel("Salloni")}</h5>
                    </div>
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className={`tab-panel-wrapper ${selectedTabIndex === 0 ? 'first-tab-margin' : ''}`} ref={searchAreaRef}>
                    <div className="input-wrapper">
                      <div className="input-container">
                        <span className="span1"></span>
                        <input
                          // defaultValue={treatmentSearchName}
                          value={treatmentSearchName ? treatmentSearchName : ""}
                          type="text"
                          placeholder={TranslateLabel("Kërko")}
                          onChange={handleSearchTreatment}
                          onFocus={handleInputFocus}
                        />
                      </div>

                      {selectedTreatment &&
                        filteredDataByTreatment?.length > 0 &&
                        activeInput === "searchTreatment" ? (
                        renderDropdown(filteredDataByTreatment, 5)
                      ) : null}
                    </div>
                    <div className="input-wrapper">
                      <div className="input-container">
                        <span className="span2"></span>
                        <input
                          value={treatmentLocationValue ? treatmentLocationValue : ""}
                          type="text"
                          placeholder={TranslateLabel("Shëno qytetin ose lagjën")}
                          onChange={handleSelectedLocation}
                          onFocus={handleInputFocus}
                        />
                      </div>
                      {searchLocation &&
                        filterDataByLocation?.length > 0 &&
                        activeInput === "selectedLocation" ? (
                        renderTreatmentLocationDropdown(filterDataByLocation, 5)
                      ) : null}
                    </div>
                    {/* <div className="input-wrapper">
                    <div className="input-container">
                      <span className="span3"></span>
                      <input type="text" placeholder="Dita 1" />
                    </div>
                  </div> */}
                    <button onClick={handleSearchPageNav} className="homepage_search_input"> {TranslateLabel("Kërko")} </button>

                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="tab-panel-wrapper" ref={searchAreaRef}>
                    <div className="input-wrapper">
                      <div className="input-container">
                        <span className="span1"></span>
                        <input
                          value={searchSalonName}
                          type="text"
                          placeholder={TranslateLabel("Kërko")}
                          // value={selectedName}
                          onChange={handleSelectedName}
                          onFocus={handleInputFocus}
                        />
                      </div>
                      {selectedSalon && selectedSalon?.length > 0 && activeInput === "selectedName" ? (
                        renderSalonNameDropdown(selectedSalon, 5)
                      ) : null}
                    </div>
                    <div className="input-wrapper">
                      <div className="input-container">
                        <span className="span2"></span>
                        <input
                          value={treatmentLocationValue}
                          type="text"
                          placeholder={TranslateLabel("Shëno qytetin ose lagjën")}
                          onChange={handleSalonLocationLocation}
                          onFocus={handleInputFocus}
                        />
                      </div>
                      {salonLocation &&
                        salonLocation?.length > 0 &&
                        activeInput === "selectedLocation" ? (
                        renderLocationDropdown(salonLocation, 5)
                      ) : null}
                    </div>
                    <div className="input-wrapper">
                      <div className="input-container">
                        <span className="span3"></span>
                        <input
                          value={selectedStaffName}
                          type="text"
                          placeholder={TranslateLabel("Stafi")}
                          onChange={handleSelectedStaffInputChange}
                          onFocus={handleInputFocus}
                        />

                      </div>
                      {selectedSalonStaff &&
                        selectedSalonStaff?.length > 0 &&
                        activeInput === "selectedStaff" ? (
                        renderStafDropdown(selectedSalonStaff, 5)
                      ) : null}
                    </div>
                    <button onClick={handleSalonPageNav} className="homepage_search_input"> {TranslateLabel("Kërko")} </button>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
          <div className="text">
            <h1 data-aos="fade-up" data-aos-delay="0">{TranslateLabel("CaktoTerminin")}</h1>
            <p data-aos="fade-up" data-aos-delay="400">{TranslateLabel("Krejt shërbimet")}</p>
          </div>
        </div>
        <h4 className=" text-center pt-lt-3 pt-0 pt-md-2 mt-lg-5  mt-md-4 mt-3 mb-5">{TranslateLabel("Sallonet e rekomanduara")}</h4>
        <div className="salons">
          {salonsToMap?.map((el, index) => {
            // console.log("salonsToDisplay", el)
            return (
              <>
                {
                  el?.salon_addresses != [] &&
                  el?.salon_addresses?.map(filter => filter?.salon_sub_services != []) &&
                  el?.salon_addresses?.map((elem) => {

                    return (
                      <>
                        <div className="salon-content" key={index}>
                          <div className="first-row">
                            <div className="slider">
                              <div className="slideshow-container">

                                <Slide onChange={(currentIndex) => setActiveIndex(currentIndex)}>
                                  {elem?.salon_imgs?.length === 0 ?

                                    <div className="slider-img no-image-placeholder">
                                      <img loading="lazy" src="https://cdn11.bigcommerce.com/s-m1jiibmpmc/stencil/080443d0-e8e7-0139-b5bb-5eca3f67671f/e/f51774b0-60cc-0138-751f-0242ac11000b/icons/icon-no-image.svg" alt="No Img Available" />
                                      <div className="slider-text">
                                        <h5>No Image Available</h5>
                                      </div>
                                    </div>
                                    :

                                    elem?.salon_imgs?.map((image, imageIndex) =>

                                      < div className="slider-img" onClick={() => handleSlideClick(imageIndex)} key={imageIndex}>
                                        <img loading="lazy" src={`${process.env.REACT_APP_API}${image?.img_url}`} alt={`Slide ${imageIndex}`} />
                                        <div className="slider-text">
                                          <h5>{image?.img_alt}</h5>
                                          <span>{imageIndex + 1} / {elem?.salon_imgs?.length}</span>
                                        </div>
                                      </div>
                                    )}
                                </Slide>

                              </div>
                            </div>
                            <div className="info">
                              <h5 className="tracking-in-expand">{el?.name_al}</h5>
                            </div>
                          </div>
                          <div className="second-row">
                            {elem?.salon_sub_services?.slice(0, 3)?.map((treatment) => {
                              return (
                                <>
                                  {
                                    treatment?.length == 0 ?
                                      <p>Shiko detajet e sallonit</p>
                                      :
                                      <div className="treatment-wrapper" key={index}>
                                        <div className="treatment-info">
                                          <h6>{props?.selected_lang === "AL" ? treatment.name_al : treatment.name_en}</h6>
                                          <p>{treatment.duration}</p>
                                        </div>
                                      </div>
                                  }
                                </>
                              )
                            })}
                          </div>
                          < div className="third-row" >
                            < Link to={`/salon/${String(el?.name_al?.replaceAll(" ", "-"))}/${String(elem?.street_address_al)?.replaceAll(" ", "-")}`}
                              state={{ salon_id: elem?.id }}
                            > {TranslateLabel("Shiko sallonin")}</Link>
                          </div>
                        </div >

                      </>
                    )

                  })
                }
              </>
            )

          })}
        </div>
        <Lightbox
          plugins={[Captions]}
          open={open !== false}
          index={activeIndex}
          close={() => setOpen(false)}
          slides={salonsToMap?.flatMap((el) =>
            el.salon_addresses?.flatMap((elem) =>
              elem.salon_imgs?.map((image) => ({
                src: `${process.env.REACT_APP_API}${image.img_url}`,
                description: image.img_alt
              }))
            )
          )}
        />
      </div>


    </>
  );
};

const mapStateToProps = (state) => ({
  selected_lang: state.data.selected_lang,
  logged_in: state.data.logged_in,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    // setSelectedLang: (data) => dispatch(setSelectedLang(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

