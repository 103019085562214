import React, { useState, useEffect } from "react";
import "./checkout.scss";
import { CheckoutButton } from "../../components/ui/buttonGroup";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlinePlus, AiOutlineCloseCircle } from "react-icons/ai";
import { connect } from 'react-redux';
import Modal from "../../components/ui/authModal";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { addAppointment, addAppointmentData } from "../../redux/Functions/actions";
import { removeAppointment } from "../../redux/Functions/actions";
import { resetAppointments } from "../../redux/Functions/actions";
import axios from "../../axios";
import { toast } from "react-toastify";
import zIndex from "@mui/material/styles/zIndex";
import Checkout2 from "./checkout2";
import { render } from "@testing-library/react";
import { isToday, getDay, addDays, isSameDay, isSameWeek, isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday, endOfYear } from 'date-fns';
import { useLocation } from 'react-router-dom';




const Checkout = (props) => {

  const { appointments, removeAppointment, logged_in } = props;
  const [Worker, setWorker] = useState()
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [workingDays, setWorkingDays] = useState()
  const [workerBusySchedule, setWorkerBusySchedule] = useState()
  const [userId, setUserId] = useState()
  const [availableStaf, setAvailableStaf] = useState()


  const handleRemoveAppointment = (index) => {
    removeAppointment(index);
  };
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Access the URL path from the location object
  const currentPath = location.pathname;
  // console.log("currentPath", currentPath)
  function setCorrectTime(data) {
    const dateObj = new Date(data);
    const userOffset = dateObj.getTimezoneOffset();
    const adjustedTime = dateObj.getTime() + userOffset * 60000;
    return new Date(adjustedTime);
  }

  const year = selectedDate?.getFullYear();
  const month = selectedDate?.getMonth() + 1;
  const date = selectedDate?.getDate();
  const dataForCheckOut2 = {
    workerId: Worker,
    selectedDate: `${year}-${month}-${date}`,
    selectedTime: selectedTime
  }
  let filteredWorketTimeSlots = [];


  const handleCheckout = () => {
    // if (logged_in) {
    const userSelectedCorrectTime = filteredWorketTimeSlots.filter(el=>el.status === "Available").find(el => el.value === selectedTime.slice(0, 5))
    if (!Worker || !selectedDate || !userSelectedCorrectTime) {
      toast.error("Ju lutem plotesoni të dhënat e nevojshme!")
    }
    else {

      props.addAppointmentData(dataForCheckOut2)

      navigate("/checkout2");
    }

    // } else {
    //   setShowModal(true);
    // }
  };

  const handleLoginSuccess = () => {
    logged_in(true);
    setShowModal(false);
    navigate("/checkout2");
  };


  const handleGetWorkerSchedule = async (e) => {
    setUserId(e?.target?.value)
    axios.get(`/api/client/appointments/singleWorker/${e?.target?.value}`)
      .then(res => {
        setWorkerBusySchedule(res.data)
      })
      .catch(err => toast.error(err))
    axios.get(`/api/client/worker_working_hours/${e?.target?.value}`)
      .then(res => {
        setWorkingDays(res.data)
      })
      .catch(err => toast.error(err))
  }

  // const handleGetWorkers = (data) => {

  //   // setSelectedName(event.target.value);
  //   axios.get(`/api/client/salon_address/salonAddressWorkers/${data?.salon_address_id}/${appointments[0]?.sub_sub_service_id}`)
  //     .then(res => {
  //       console.log('resrasd', res)
  //       setAvailableStaf(res.data)
  //       // setSelectedSalon(res.data.data)
  //       // setShowDropdown(event.target.value.length > 0);
  //       // setActiveInput("selectedName");
  //     })
  //     .catch(err => {
  //       console.log("error", err)
  //     })

  // };

  const handleGetWorkers = (data) => {
    axios.get(`/api/client/salon_address/salonAddressWorkers/${appointments[0]?.salon_address_id}/${appointments[0]?.sub_sub_service_id}`)
      .then(res => {
        setAvailableStaf(res.data)
        setHasAvailableStaff(res.data.length > 0);
      })
      .catch(err => {
        console.log("error", err)
        setHasAvailableStaff(false);
      });
  };




  const CustomDatePicker = () => {
    const handleDateChange = async (date) => {
      // Add 2 hours to the date
      const newDate = new Date(date)
      newDate.setHours(newDate.getHours() + 2);
      setSelectedDate(newDate);
      setSelectedTime("00:00:00")

    };
    const disabledDayNames = workingDays?.filter(el => el?.work_this_day !== false).map(elem => elem?.day); // Days to be disabled

    const disableSpecificDays = (date) => {
      // Check if the day of the week is disabled
      const dayName = date.toLocaleString('en-US', { weekday: 'long' });
      return disabledDayNames.includes(dayName);
    };
    // const isWorkDay = async (workingDays) => {
    //   let daysOffIndex = await workingDays?.map(el =>
    //     // console.log('el kaja belane', el)
    //     el?.day == "Sunday" ? 0 :
    //       el?.day == "Monday" ? 1 :
    //         el?.day == "Tuesday" ? 2 :
    //           el?.day == "Wednesday" ? 3 :
    //             el?.day == "Thursday" ? 4 :
    //               el?.day == "Friday" ? 5 :
    //                 el?.day == "Saturday" ? 6
    //                   : null
    //   )
    //   const day = getDay(daysOffIndex);
    //   console.log("daydaydayday", day)
    //   return day !== 0 && day !== 6;
    // };

    return (
      <div className="date-picker  ">
        <DatePicker
          minDate={new Date()}
          maxDate={endOfYear(new Date())}
          disabled={!workingDays ? true : false}
          placeholderText={!workingDays ? "Ju lutem zgjedhni stafin " : "Selekto daten"}
          showDisabledMonthNavigation
          // scrollableYearDropdown
          // scrollableMonthDropdown
          // showMonthDropdown
          // selected={appointments[0]?.additionalData?.selectedDate}
          filterDate={disableSpecificDays}
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          className="customDatePicker form-select rounded  "
        />
      </div>
    );
  };
  let intervaliTermineve = props?.interval ? props?.interval * 60 * 1000 : 30 * 60 * 1000


  const CustomTimePicker = () => {

    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();

    // Helper function to format time
    function formatTime(date) {
      return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0');
    }

    // Helper function to check if two time ranges overlap
    function doTimeRangesOverlap(start1, end1, start2, end2) {
      return start1 < end2 && end1 > start2;
    }

    const selectedDayWorkingHours = new Date(selectedDate)?.toLocaleDateString('en-US', { weekday: 'long' });
    const schedule = workingDays?.find(el => el?.day === selectedDayWorkingHours);

    let wholeSchedule = [];
    if (schedule !== undefined) {
      let AM_Schedule = setCorrectTime(schedule?.start_time);

      while (AM_Schedule < setCorrectTime(schedule?.end_time)) {
        const timeValue = formatTime(AM_Schedule);

        // Check if the time slot is within the break time range
        const isBreakTime = doTimeRangesOverlap(
          AM_Schedule,
          new Date(AM_Schedule.getTime() + (intervaliTermineve)), // Adding 30 minutes to get end time
          setCorrectTime(schedule?.brake_start),
          setCorrectTime(schedule?.brake_end)
        );

        wholeSchedule.push({
          value: timeValue,
          label: timeValue,
          status: isBreakTime ? "Pauza" : "Available"
        });

        AM_Schedule = new Date(AM_Schedule.getTime() + intervaliTermineve);
      }
    }

    const takenTimes = workerBusySchedule?.map(appointment => ({
      date_of_appt: appointment?.date_of_appointment,
      start: appointment.app_start,
      end: appointment.app_end
    }));
    // ... Your previous code ...

    // Mark slots that are busy
    let selecteddDate = new Date(selectedDate);
    selecteddDate.setHours(selecteddDate.getHours() + 2);
    let selectDate = selecteddDate?.toISOString().slice(0, 10);

    wholeSchedule?.forEach(timeObj => {
      const selectedStartTime = new Date(`${selectDate}T${timeObj.value}:00`);
      const selectedEndTime = new Date(selectedStartTime.getTime() + (30 * 60 * 1000)); // Adding 30 minutes to get end time

      // Check if the selected time range overlaps with any existing appointment
      const isBusy = takenTimes?.some(appointment => {
        const existingDate = appointment?.date_of_appt;
        const existingStartTime = new Date(`${existingDate}T${appointment?.start}`);
        const existingEndTime = new Date(`${existingDate}T${appointment?.end}`);

        return (
          (selectedStartTime >= existingStartTime && selectedStartTime < existingEndTime) ||
          (selectedEndTime > existingStartTime && selectedEndTime <= existingEndTime)
        );
      });

      if (isBusy) {
        timeObj.status = "Busy";
      }
    });




    const filteredOptions = wholeSchedule.map((el) => {
      const [hour, minute] = el.value.split(":").map(Number);
      const isPreviousHour =
        selectedDate &&
        isToday(selectedDate) &&
        (hour < currentHour || (hour === currentHour && minute < currentMinute));

      return {
        ...el,
        isPreviousHour,
      };
    });
    filteredWorketTimeSlots = filteredOptions
    return (
      <div className="time-picker col-12">
        <select
          value={selectedTime !== "00:00:00" ? selectedTime?.slice(0, 5) : null}
          onChange={(e) => { e.preventDefault(); setSelectedTime(`${e.target.value}:00`) }}
          disabled={selectedDate ? false : true}
          name="selectedTimeSlot"
          id="selectedTimeSlot"
          className="time-select form-select rounded  "
        >
          <option value={null} selected disabled hidden>{!Worker ? "Ju lutem zgjedhni stafin" : !selectedDate ? "Selekto daten" : "Zgjedh oren"}</option>
          {/* {wholeSchedule?.map(el =>
            <>
              <option disabled={el?.status !== "Available" ? true : ""} className={el?.status !== "Available" ? "busySlot" : "available"} value={el.value}>{el.status !== "Available" ? el?.label + " - " + el?.status : el.label}</option>
            </>
          )
          } */}
          {filteredOptions?.map((el) => (
            <option
              key={el.value}
              selected={selectedTime !== "00:00:00" && appointments[0]?.additionalData?.selectedTime?.slice(0, 5) == el?.value}
              disabled={el.status !== "Available" || el.isPreviousHour}
              className={`${el.status !== "Available" ? "busySlot" : "available"} ${el.isPreviousHour ? "disabled" : ""}`}
              value={el.value}
            >
              {el.status !== "Available" ? `${el.label} - ${el.status}` : el.label}
            </option>
          ))}
        </select>
      </div >
    );

  };

  useEffect(() => { CustomTimePicker(); }, [selectedDate])
  useEffect(() => { setSelectedTime("00:00:00") }, [selectedDate])
  useEffect(() => {
    // console.log('popo hina2', appointments[0]?.additionalData?.selectedDate, currentPath == "/checkout")
    // if (appointments[0]?.additionalData?.selectedDate && currentPath == "/checkout") {
    //   console.log('popo hina', appointments[0]?.additionalData?.workerId)
    //   axios.get(`/api/client/appointments/singleWorker/${appointments[0]?.additionalData?.workerId}`)
    //     .then(res => {
    //       setWorkerBusySchedule(res.data)
    //     })
    //     .catch(err => toast.error(err))
    //   axios.get(`/api/client/worker_working_hours/${appointments[0]?.additionalData?.workerId}`)
    //     .then(res => {
    //       setWorkingDays(res.data)
    //     })
    //     .catch(err => toast.error(err))
    // }
    handleGetWorkers()
    if (appointments[0]?.additionalData?.workerId) {
      // handleGetWorkerSchedule(appointments[0]?.additionalData?.workerId)
      setWorker(appointments[0]?.additionalData?.workerId)
      axios.get(`/api/client/appointments/singleWorker/${appointments[0]?.additionalData?.workerId}`)
        .then(res => {
          setWorkerBusySchedule(res.data)
        })
        .catch(err => toast.error(err))
      setWorker(appointments[0]?.additionalData?.workerId)
      setSelectedDate(new Date(appointments[0]?.additionalData?.selectedDate))
      setSelectedTime(appointments[0]?.additionalData?.selectedTime)
      axios.get(`/api/client/worker_working_hours/${appointments[0]?.additionalData?.workerId}`)
        .then(res => {
          setWorkingDays(res.data)
        })
        .catch(err => toast.error(err))
    }
  }, [])

  const [hasAvailableStaff, setHasAvailableStaff] = useState(false);


  return (
    <div className="checkout-layout">
      <div className="basket-container ">
        <h4 className="basket tracking-in-expand">Shporta</h4>
        {appointments?.map((appointment, index) => (
          <div key={appointment.salon_id} className="appointment-card">
            <div className="row">
              <div className="col-12 col-md-7 col-lg-8 checkout-basket-right">
                <hr className="" />
                {console.log("props", props)}

                <div className="row">
                  <div className="appointments col-2">
                    <h5 className="appointmentName mt-3">{appointment?.name_al}</h5>
                    <p >{appointment?.time}</p>
                  </div>
                  <div className="chooseOption col-10 justify-content-between" >
                    <div className="row">
                      <div className="chooseStaff worker col-md-3 col-sm-12 col-lg">
                        <p>Zgjedh stafin</p>
                        {/* <select
                          onClick={(e) => handleGetWorkers(appointment)}
                          onChange={(e) => { handleGetWorkerSchedule(e); setWorker(e.target.value) }}
                          className="form-select rounded  "
                          aria-label="Default select example"
                          defaultValue={null}>
                          <option value={null}>Select Worker</option>
                          {availableStaf && availableStaf?.map((el, index) =>
                            <>
                              <option value={el?.id} name="selected_worker" >{el?.name} {el?.surname}</option>
                            </>
                          )}
                        </select> */}
                        <select
                          // defaultChecked={!props?.appointments[0]?.additionalData?.workerId ? null : props?.appointments[0]?.additionalData?.workerId}
                          // onClick={(e) => handleGetWorkers(appointment)}
                          onChange={(e) => { handleGetWorkerSchedule(e); setWorker(e.target.value) }}
                          className="form-select rounded"
                          aria-label="Default select example"
                        >
                          <option value={null} hidden selected disabled >Selekto Stafin</option>
                          {hasAvailableStaff ? (
                            <>
                              {availableStaf && availableStaf.map((el, index) => (
                                <option selected={props?.appointments[0]?.additionalData?.workerId == el?.id} key={el?.id} value={el?.id} name="selected_worker">
                                  {el?.name} {el?.surname}
                                </option>
                              ))}
                            </>
                          ) : (
                            <option disabled>Asnjë staf i disponueshem</option>
                          )}
                        </select>


                      </div>
                      <div className="chooseStaff date col-md-3 col-sm-12 col-lg">
                        <p>Zgjedh datën e terminit</p>
                        <CustomDatePicker />
                      </div>
                      <div className="chooseStaff time col-md-3 col-sm-12 col-lg">
                        <p>Zgjedh orën e terminit</p>
                        <CustomTimePicker />
                      </div>
                      <div className="w-auto">
                        <AiOutlineCloseCircle className="x-button" onClick={() => handleRemoveAppointment(index)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* <Link to="/searchResult">
          <p className="d-flex align-items-center" style={{ color: "#ff5758", cursor: "pointer" }}>
            <AiOutlinePlus className="me-2" /> Shto edhe një shërbim nga Menda hair.
          </p>
        </Link> */}
        {/* <CheckoutButton /> */}

        <button className="choose-btn" onClick={handleCheckout}>
          Cakto Terminin
        </button>
      </div>
      {showModal && <Modal closeModal={() => setShowModal(false)} onLoginSuccess={handleLoginSuccess} />}
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    appointments: state.data.appointments,
    logged_in: state.data.logged_in,
    interval: state.data.interval
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAppointment: (apptData) => dispatch(addAppointment(apptData)),
    addAppointmentData: (apptData) => dispatch(addAppointmentData(apptData)),
    removeAppointment: (index) => dispatch(removeAppointment(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
