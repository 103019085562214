import React from "react";
import "./footer.scss";
import { SocialMediaLinks } from "../ui/buttonGroup";


const Footer = () => {
  return (
    <div className="footer-layout">
      <SocialMediaLinks />
    </div>
  );
};

export default Footer;
