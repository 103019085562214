import { useSelector } from 'react-redux';

function TranslateLabel(key) {
    const langData = useSelector(state => state.data.lang_data);
    const selected_lang = useSelector(state => state.data.selected_lang);

    if (selected_lang === 'AL') {
        let translate = langData?.filter(el => el.key === key)[0]?.label_al;
        return translate
    }
    else {
        let translate = langData?.filter(el => el.key === key)[0]?.label_en;
        return translate
    }


}

export default TranslateLabel;