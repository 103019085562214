import "./App.scss";
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import Header from "./components/header/header";
import Homepage from "./pages/Home/homepage";
import SearchResult from "./pages/SearchResult/searchResultPage";
import Checkout from "./pages/Checkout/checkout";
import Footer from "./components/footer/footer";
import SalonPage from "./pages/Salon/salonPage";
import Checkout2 from "./pages/Checkout/checkout2";
import Checkout3 from "./pages/Checkout/checkout3";
import UserProfile from "./pages/userProfile/userProfile";
import Aos from 'aos'
import "aos/dist/aos.css"
import { useEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux";
import TermsAndConditions from "./pages/termsAndConditions/termsAndConditions";
// import Redirect from "./components/redirectComponent/redirect";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "./axios";
import { setLangData } from "./redux/Functions/actions";

function App(props) {



  useEffect(() => {
    Aos.init({
      duration: 1250,
      once: true
    })
  }, []);

  useEffect(() => {
    axios.get("/api/labels/all")
      .then((res) => {
        props?.setLangData(res?.data)
      })
      .catch((err) => console.log('error fetching labels : ', err))
  }, [])

  const CustomRedirect = () => {
    const { name, address } = useParams();
    const navigate = useNavigate();
    // console.log("name, address ", name, address)
    // Function to handle redirection

    const redirectToSearchResult = () => {
      if (name && !address) {
        const searchQuery = name.replaceAll("-", " ");
        // console.log("searchQuery", name, searchQuery)
        // http://localhost:3000/searchResult/searchData/all?salonName=tes
        const targetUrl = `/searchResult/searchData/all?salonName=${searchQuery}`;
        return <Navigate to={targetUrl} />;

      }
      else if (name && address) {
        // console.log("name, address", name, address)
        const salonName = name.replaceAll("-", " ")?.replaceAll("-", "_");
        const salonAddress = address.replaceAll("-", " ")?.replaceAll("-", "_");
        // http://localhost:3000/searchResult/searchData/all?salonName=tes
        const targetUrl = `/salon/${salonName}/${salonAddress}`;
        // <Route path="/salon/:id" element={<SalonPage />} />
        return <Navigate to={targetUrl} />;

      }
    };

    // No need for the Route element here
    return redirectToSearchResult();
  };



  return (
    <>
      <ToastContainer position="bottom-right" theme="colored" style={{ zIndex: '9999' }} />

      <Router>
        <Header />
        <div className="pages-container">
          <ScrollToTop />
          <Routes>

            <Route path="/" element={<Homepage />} />
            <Route path="*" element={<Homepage />} />
            <Route path="/reset-password/:password_token" element={<Homepage />} />
            <Route path="/searchResult/:searchType/:searchQuery" element={<SearchResult />} />
            {/* <Route exact path="/searchResult/:searchQuery" element={<SearchResult />} /> */}
            <Route path="/salon/:id" element={<SalonPage />} />
            <Route path={"/:name"} element={<CustomRedirect />} />
            <Route path={"/:salonName/:salonAddress"} element={<CustomRedirect />} />
            <Route path="/salon/:salonName/:salonAddress" element={<SalonPage />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout2" element={<Checkout2 />} />
            <Route path="/checkout3" element={props?.logged_in === true ? <Checkout3 /> : <Navigate to="/" />} />
            <Route path="/profile" element={props?.logged_in === true ? <UserProfile /> : <Navigate to="/" />} />
            <Route path="/profile" element={props?.logged_in === true ? <UserProfile /> : <Navigate to="/" />} />

          </Routes>
        </div>
        <Footer />
      </Router>
    </>
  );
}

// in order to get data from redux you need to call their value from te store of redux by imporijng it and by using connect from redux we pass all of that data that we imported as props to our component and-or page ! 
const mapStateToProps = state => ({
  selected_lang: state.data.selected_lang,
  logged_in: state.data.logged_in,
});

const mapDispatchToProps = dispatch => {
  return {
    setLangData: (data) => dispatch(setLangData(data)),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(App)