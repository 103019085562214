import React, { useEffect, useState, useRef } from "react";
import "./searchResult.scss";
import { Link, useParams, useLocation } from "react-router-dom";
import { FaStar } from 'react-icons/fa'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import Slideshow from "../../components/Slider/slideShow";
import axios from "../../axios";
import ReactPaginate from 'react-paginate'
import ThonjeImg from '../../assets/images/1-thonje.png'
import DepilimImg from '../../assets/images/2-depilim.png'
import FytyreImg from '../../assets/images/3-fytyre.png'
import FolkImg from '../../assets/images/4-floke.png'
import DefaultSearchImg from '../../assets/images/search-result-banner.webp'
import "./slider.scss"
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { connect, useSelector } from "react-redux";

const SearchResult = (props) => {

  const [currentLocation, setCurrentLocation] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [loadingLocation, setLoadingLocation] = useState(true);


  const location = useLocation();
  // console.log("location", location?.search?.split("=")[1]?.split("&"))

  const tretmaniKerkuar = location?.search?.split("=")[1]?.split("&")[0]
  // get the type of api call to make
  // get the query parameters
  const searchParams = new URLSearchParams(location.search);
  const treatment = searchParams.get('treatment');
  const treatmentLocation = searchParams.get('treatmentLocation');
  const salonName = searchParams.get('salonName');
  const salonAddress = searchParams.get('salonAddress');
  const staff = searchParams.get('staff');
  const [Data, setData] = useState([])
  const [address, setAddress] = useState([])

  // -------------------- PAGINATION ------------------------ //

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 6;
  const pageCount = Math.ceil(Data?.data?.length / itemsPerPage);

  const startIndex = pageNumber * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const salons = Data?.data?.slice(startIndex, endIndex);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  // ------------------------------------------------------ //

  // search
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSalons, setFilteredSalons] = useState(salons);

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    const lowerCaseSearchTerm = searchValue?.toLowerCase();
    const filteredList = salons.filter((el) => {
      const salonName = el?.name_al?.toLowerCase();
      return salonName.includes(lowerCaseSearchTerm);
    });

    setFilteredSalons(filteredList);
  };

  // ============================



  const handeleGetFromQuery = () => {
    axios.get(
      `/api/searchData/all?${treatment ? `treatment=${treatment}&` : ""}${treatmentLocation !== null ? `treatmentLocation=${treatmentLocation}` : ""}`
    )
      .then((res) => {
        setData(res.data)
        setAddress(res?.data?.data[0].salon_addresses[0].name_al)
      })
      .catch(err => console.log('error', err))
  }

  const handeleGetFromHeaderQuery = () => {
    axios.get(
      `/api/searchData/all?${treatment ? `treatment=${treatment}` : ""}`
    )
      .then((res) => {
        setData(res.data)
        setAddress(res?.data?.data[0].salon_addresses[0].name_al)
      })
      .catch(err => console.log('error', err))
  }
  const handeleGetFromHeaderQuery1 = () => {
    axios.get(
      `/api/salonSearchData/all${salonName ? `?salonName=${salonName}` : ''}${salonAddress ? `${!salonName ? '?' : '&'}salonAddress=${salonAddress}` : ''}${staff ? `${!salonName ? '?' : '&'}staff=${staff}` : ''}`
    )
      .then((res) => {
        setData(res.data)
        setAddress(res?.data?.data[0].salon_addresses[0].name_al)
      })
      .catch(err => console.log('error', err))
  }

  const [headerOptions, setHeaderOptions] = useState([])
  const salonsToDisplay = searchTerm ? filteredSalons : salons;

  function handleGetServices() {
    axios.get("/api/client/services/all_ser")
      .then(res => setHeaderOptions(res.data))
      .catch(err => console.log('errorGetting services', err))
  }
  function getCategoryName(treatment) {
    const lowerCaseTreatment = treatment?.toLowerCase();
    for (const category of headerOptions) {
      if (lowerCaseTreatment.startsWith(decodeURIComponent(category?.name_al?.toLowerCase())) || lowerCaseTreatment.startsWith(decodeURIComponent(category?.name_en?.toLowerCase()))) {
        if (props?.selected_lang === "AL") { return category.name_al; }
        if (props?.selected_lang === "EN") { return category.name_en; }

      }
    }
    return `Nuk ka sallone që kryejn shërbime për ${decodeURIComponent(treatment?.toLowerCase())}`; // Return default message if no category found
  }
  const categoryName = getCategoryName(decodeURIComponent(tretmaniKerkuar));
  // console.log("tretmaniKerkuar", decodeURIComponent(tretmaniKerkuar))
  const message =
    salonsToDisplay?.length < 1 ?
      `Nuk ka sallone që kryejn shërbime për "${decodeURIComponent(treatment?.toLowerCase())}"` :
      // `${TranslateLabel("Sallonet e")} ${categoryName} `;
      `${TranslateLabel("Sallonet e")} ${treatment?.toLowerCase().startsWith("th")
        ? TranslateLabel("thonjëve")
        : treatment?.toLowerCase().startsWith("he")
          ? TranslateLabel("depilimit")
          : treatment?.toLowerCase().startsWith("fy")
            ? TranslateLabel("fytyrës")
            : treatment?.toLowerCase().startsWith("fl")
              ? TranslateLabel("flokëve")
              : treatment?.toLowerCase().startsWith("f")
                ? TranslateLabel("flokëve dhe fytyrës")
                : categoryName
      }`

  // const handeleGetFromHeaderLocationQuery = () => {
  //   alert("hina")
  //   axios.get(
  //     `/api/searchData/all?${treatmentLocation ? `treatmentLocation=${treatmentLocation}` : ""}`
  //   )
  //     .then((res) => {

  //       setData(res.data)
  //       setAddress(res?.data?.data[0].salon_addresses[0].name_al)
  //     })
  //     .catch(err => console.log('error', err))
  // }

  useEffect(() => {
    if (treatment && !treatmentLocation) {
      handeleGetFromHeaderQuery();
    }
    if (salonName || salonAddress || staff) {
      handeleGetFromHeaderQuery1();
    }
  }, [treatment]);

  // useEffect(() => {
  //   if (treatmentLocation) {
  //     handeleGetFromHeaderLocationQuery();
  //   }
  // }, [treatmentLocation]);

  useEffect(() => {
    if (treatment && treatmentLocation) {
      handeleGetFromQuery();
    }
  }, [treatment, treatmentLocation]);

  function TranslateLabel(key) {
    const langData = props.lang_data;
    const selected_lang = props.selected_lang
    if (selected_lang === 'AL') {
      let translate = langData?.filter(el => el.key === key)[0]?.label_al;
      return translate
    }
    else {
      let translate = langData?.filter(el => el.key === key)[0]?.label_en;
      return translate
    }


  }

  useEffect(() => {
    handleGetServices()
    let delay;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`)
            .then((response) => response.json())
            .then((data) => {
              const locationName = data.address.city || data.address.town || data.address.village || data.address.hamlet || '';
              setCurrentLocation(locationName);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        },
        (error) => {
          console.error('Error:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setCurrentLocation('N/A');
    }

    const setLoadingWithDelay = () => {
      delay = setTimeout(() => {
        setLoadingLocation(false);
      }, 500);
    };



    const getCurrentDate = () => {
      const months = [TranslateLabel('Janar'), TranslateLabel('Shkurt'), TranslateLabel('Mars'), TranslateLabel('Prill'), TranslateLabel('Maj'), TranslateLabel('Qershor'), TranslateLabel('Korrik'), TranslateLabel('Gusht'), TranslateLabel('Shtator'), TranslateLabel('Tetor'), TranslateLabel('Nëntor'), TranslateLabel('Dhjetor')];
      const days = [TranslateLabel('E Diel'), TranslateLabel('E Hënë'), TranslateLabel('E Martë'), TranslateLabel('E Mërkurë'), TranslateLabel('E Enjte'), TranslateLabel('E Premte'), TranslateLabel('E Shtunë')];

      const date = new Date();
      const month = date.getMonth();
      const day = date.getDay();
      const currentDateText = `${date.getDate()} ${months[month]}, ${days[day]}`;
      setCurrentDate(currentDateText);
    }


    setLoadingWithDelay();
    getCurrentDate();

  }, [props.selected_lang]);


  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideClick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };

  // const renderSlider = (salon, index) => {
  //   console.log("address", salon, index)
  //   if (salon?.some((address) => address.salon_imgs?.length > 0)) {
  //     return (
  //       <Slide onChange={(currentIndex) => setActiveIndex(currentIndex)}>
  //         {salon?.map((elem) =>
  //           elem.salon_imgs?.map((image, index) => (
  //             // console.log("hejToni",image)
  //             <div className="slider-img" onClick={() => handleSlideClick(index)} key={index}>
  //               <img src={`${process.env.REACT_APP_API}${image.img_url}`} alt={`Slide ${index}`} />
  //               <div className="slider-text">
  //                 <h5>{image.img_alt}</h5>
  //                 <span>{index + 1} / {elem.salon_imgs?.length}</span>
  //               </div>
  //             </div>
  //           ))
  //         )}
  //       </Slide>
  //     );
  //   } else {
  //     return (
  //       <div className="slider-img no-image-placeholder">
  //         <img src="https://cdn11.bigcommerce.com/s-m1jiibmpmc/stencil/080443d0-e8e7-0139-b5bb-5eca3f67671f/e/f51774b0-60cc-0138-751f-0242ac11000b/icons/icon-no-image.svg" alt="No Img Available" />
  //         <div className="slider-text">
  //           <h5>No Image Available</h5>
  //         </div>
  //       </div>
  //     );
  //   }
  // };


  return (
    <div className="search-result-layout">
      <div className="search-result-banner" style={{
        backgroundImage: `url(${tretmaniKerkuar?.toLowerCase().indexOf("fl") == 0 ? FolkImg :
          tretmaniKerkuar?.toLowerCase().indexOf("th") == 0 ? ThonjeImg :
            tretmaniKerkuar?.toLowerCase().indexOf("he") == 0 ? DepilimImg :
              tretmaniKerkuar?.toLowerCase().indexOf("fy") == 0 ? FytyreImg :
                DefaultSearchImg})`
      }}>
        <div className="content">
          <div className="left">
            <div className="input-container" data-aos="fade-left">
              <span className="search-icon"></span>
              <input
                type="text"
                placeholder={tretmaniKerkuar?.toLowerCase().indexOf("th") == 0 ? `${TranslateLabel("Sallonet e")} ${TranslateLabel("thonjëve")}` :
                  tretmaniKerkuar?.toLowerCase().indexOf("he") == 0 ? `${TranslateLabel("Sallonet e")} ${TranslateLabel("depilimit")}` :
                    tretmaniKerkuar?.toLowerCase().indexOf("fy") == 0 ? `${TranslateLabel("Sallonet e")} ${TranslateLabel("fytyrës")}` :
                      tretmaniKerkuar?.toLowerCase().indexOf("fl") == 0 ? ` ${TranslateLabel("Sallonet e")} ${TranslateLabel("flokëve")}` :
                        tretmaniKerkuar?.toLowerCase().indexOf("f") == 0 ? `${TranslateLabel("Sallonet e")} ${TranslateLabel("flokëve")}` : `${TranslateLabel("Sallonet e")} ${TranslateLabel("kategorisë")} ` + categoryName.toLowerCase()}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="location-date-wrapper">
              {address[0] && <span>{address}</span>}
              <span>{currentDate}</span>
            </div>
          </div>
          <div className="right">
            <h4 data-aos="fade-right">{
              // salonName ? `Adresat e sallonit "${salonName.charAt(0).toUpperCase() + salonName?.slice(1)}"` :
              // headerOptions?.map(el=>el?.name_al?.toLowerCase().indexOf("th") == 0 ? salonsToDisplay?.length === 0 ? "Nuk ka sallone të regjistruara që kryejn shërbimin e kërkuar" : "Sallonet e thonjëve")
              message
              // `Nuk ka sallon të kategorisë "${decodeURIComponent(String(tretmaniKerkuar?.toLowerCase()))}"`
            }</h4>
          </div>
        </div>
      </div>

      <div className="search-result-content">
        <div className="top">
          {Data?.data?.length == 0 ?
            <p>Nuk ështe gjetur asnje sallon per kategorinë {tretmaniKerkuar?.toLowerCase().indexOf("th") == 0 ? "thonjëve" :
              tretmaniKerkuar?.toLowerCase().indexOf("he") == 0 ? '"depilimit"' :
                tretmaniKerkuar?.toLowerCase().indexOf("fy") == 0 ? '"fytyrës"' :
                  tretmaniKerkuar?.toLowerCase().indexOf("fl") == 0 ? '"flokëve"' :
                    tretmaniKerkuar?.toLowerCase().indexOf("f") == 0 ? '"flokëve dhe fytyrës"' :
                      `e kerkuar`
            }</p>
            :
            <p>{TranslateLabel("Zgjedh nga")}&#160;{Data?.data?.length} {TranslateLabel("sallone për kategorinë")} {
              tretmaniKerkuar?.toLowerCase().indexOf("he") == 0 ? '"depilim"' :
                tretmaniKerkuar?.toLowerCase().indexOf("fy") == 0 ? '"fytyrë"' :
                  tretmaniKerkuar?.toLowerCase().indexOf("fl") == 0 ? '"flokë"' :
                    tretmaniKerkuar?.toLowerCase().indexOf("f") == 0 ? '"flokëve dhe fytyrës"' : '"' + categoryName + '"'} {TranslateLabel("caktoniSherbimeNeLokacioninLabel")}&#160;{address[0] && address}.</p>
          }
        </div>
        <div className="salons-wrapper mt-3">
          <div className="salons">
            {salonsToDisplay?.map((el, index) => {
              // console.log("salonsToDisplay", el)
              return (
                <>
                  {
                    el?.salon_addresses != [] &&
                    el?.salon_addresses?.map(filter => filter?.salon_sub_services != []) &&
                    el?.salon_addresses?.map((elem) => {

                      return (
                        <>
                          <div className="salon-content" key={index}>
                            <div className="first-row">
                              <div className="slider">
                                <div className="slideshow-container">

                                  {/* {console.log("elem?.salon_imgs", elem?.salon_imgs.length)} */}
                                  <Slide onChange={(currentIndex) => setActiveIndex(currentIndex)}>
                                    {elem?.salon_imgs?.length === 0 ?

                                      <div className="slider-img no-image-placeholder">
                                        <img loading="lazy" src="https://cdn11.bigcommerce.com/s-m1jiibmpmc/stencil/080443d0-e8e7-0139-b5bb-5eca3f67671f/e/f51774b0-60cc-0138-751f-0242ac11000b/icons/icon-no-image.svg" alt="No Img Available" />
                                        <div className="slider-text">
                                          <h5>No Image Available</h5>
                                        </div>
                                      </div>
                                      :

                                      elem?.salon_imgs?.map((image, imageIndex) =>

                                        < div className="slider-img" onClick={() => handleSlideClick(imageIndex)} key={imageIndex}>
                                          <img loading="lazy" src={`${process.env.REACT_APP_API}${image?.img_url}`} alt={`Slide ${imageIndex}`} />
                                          <div className="slider-text">
                                            <h5>{image?.img_alt}</h5>
                                            <span>{imageIndex + 1} / {elem?.salon_imgs?.length}</span>
                                          </div>
                                        </div>
                                      )}
                                  </Slide>

                                </div>
                              </div>
                              <div className="info">
                                <h5 className="tracking-in-expand">{el?.name_al}</h5>
                                {/* <div className="rating">
                                  <span className="rating-number">5.0{el?.salon?.rating}</span>
                                  {Array(4).fill(<span className="star-icon"><FaStar /></span>)}
                                </div> */}
                                <p>{elem?.name_al} - {elem?.street_address_al}</p>
                              </div>
                            </div>
                            <div className="second-row">
                              {elem?.salon_sub_services?.slice(0, 3)?.map((treatment) => {
                                return (
                                  <>
                                    {
                                      treatment?.length == 0 ?
                                        <p>Shiko detajet e sallonit</p>
                                        :
                                        <div className="treatment-wrapper" key={index}>
                                          <div className="treatment-info">
                                            <h6>{props?.selected_lang === "AL" ? treatment.name_al : treatment.name_en} {treatment?.price ? " - " + treatment?.price + "€ " : " "}: </h6>
                                            <p>{treatment.duration}</p>
                                          </div>
                                        </div>
                                    }
                                  </>
                                )
                              })}
                            </div>
                            < div className="third-row" >
                              < Link to={`/salon/${String(el?.name_al?.replaceAll(" ", "-"))}/${String(elem?.street_address_al)?.replaceAll(" ", "-")}`}
                                state={{ salon_id: elem?.id }}
                              > {TranslateLabel("Shiko sallonin")}</Link>
                            </div>
                          </div >

                        </>
                      )

                    })
                  }
                </>
              )

            })}
          </div>
          <Lightbox
            plugins={[Captions]}
            open={open !== false}
            index={activeIndex}
            close={() => setOpen(false)}
            slides={salons?.flatMap((el) =>
              el.salon_addresses?.flatMap((elem) =>
                elem.salon_imgs?.map((image) => ({
                  src: `${process.env.REACT_APP_API}${image.img_url}`,
                  description: image.img_alt
                }))
              )
            )}
          />
          {/* <div className="map-wrapper">
            <GoogleMap
              className="mapAbout"
              location={{ lat: 41.3275, lng: 19.8187 }}
              height="100%"
              width="100%"
            />
          </div> */}
        </div>
        {pageCount > 1 &&
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={<IoIosArrowBack />}
              nextLabel={<IoIosArrowForward />}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        }
      </div >
    </div >
  )
}

const mapStateToProps = state => ({
  selected_lang: state.data.selected_lang,
  lang_data: state.data.lang_data,
  logged_in: state.data.logged_in,
});

const mapDispatchToProps = dispatch => {
  return {
    // setLangData: (data) => dispatch(setLangData(data)),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchResult)