import React, { useState } from "react";
import "./checkout.scss"
import { Link } from "react-router-dom";
import Slideshow from "../../components/Slider/slideShow";
import GoogleMap from "../../components/Map/GoogleMap";
import { useLocation } from 'react-router-dom';
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const Checkout3 = () => {
  const location = useLocation();
  const appointmentData = location.state?.appointmentData || null;
  const client_email = location.state?.client_email || "";
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  // console.log("location", location)
  const handleSlideClick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };
  return (
    <div className="checkout3-layout">

      <div className="checkout3-banner">
        <h1 data-aos="fade-up" data-aos-duration="2000"> {appointmentData?.newAppointment?.status == 5 ? "Bravo! Keni bë kërkesë për termin me 3 klikime!" : "Bravo! E ki caktu terminin me 3 klikime!"}</h1>
      </div>

      <div className="checkout-affirmation">
        <div className="inline" data-aos="fade-right" data-aos-delay="100">
          <div className="check"></div>
          <p>{appointmentData?.newAppointment?.status == 5 ? "Termini juaj është në pritje!" : "Termini u caktu me sukses!"} </p>
        </div>

        <div className="inline" data-aos="fade-right" data-aos-delay="200">
          <div className="check"></div>
          <p>Ne kemi njoftuar <b style={{ color: '#393d3f' }}>{appointmentData?.foundSalon?.name_al} </b> për detajet e terminit tuaj. </p>
        </div>

        <div className="inline" data-aos="fade-right" data-aos-delay="300">
          <div className="check"></div>
          <p>Ne kemi dërguar një përmbledhje të porosisë tuaj në <b style={{ color: '#393d3f' }}>{client_email}</b>.</p>
        </div>
      </div>

      <div className="checkout-details">
        <div className="details-wrapper">
          <p>Detajet e terminit</p>
          <div className="info-row">
            <div className="info">
              <div className="dateAndTime">
                <h1>{appointmentData?.newAppointment?.app_start.slice(0, 5)}</h1>
                <div className="date">
                  <h6>
                    {appointmentData?.newAppointment?.date_of_appointment.slice(0, 10)}
                    {/* E Premte, 27 JAN */}
                  </h6>
                  <p>{appointmentData?.newAppointment?.service_data?.duration}</p>
                </div>
              </div>
              <div className="details">
                <h5>{appointmentData?.newAppointment?.service_data?.name_al} </h5>
                <p>Termini te {appointmentData?.newAppointment?.salon_data?.salon_address?.users[0]?.name} {appointmentData?.newAppointment?.salon_data?.salon_address?.users[0]?.surname}</p>
              </div>
            </div>
            <div className="slider">
              <div className="slideshow-container">
                <Slide onChange={(currentIndex) => setActiveIndex(currentIndex)} >
                  {appointmentData?.newAppointment?.salon_data?.salon_address?.salon_imgs?.map((image, index) => (
                    <div className="slider-img" onClick={() => handleSlideClick(index)}>
                      <img src={`${process.env.REACT_APP_API}${image.img_url}`} alt={`Slide ${index}`} />
                      <div className="slider-text">
                        <h5>{image.img_alt}</h5>
                        <span>{index + 1} / {appointmentData?.newAppointment?.salon_data?.salon_address?.salon_imgs?.length}</span>
                      </div>
                    </div>
                  ))}
                </Slide>

                <Lightbox
                  plugins={[Captions]}
                  open={open !== false}
                  index={activeIndex}
                  close={() => setOpen(false)}
                  slides={appointmentData?.newAppointment?.salon_data?.salon_address?.salon_imgs?.map((image) => ({ src: `${process.env.REACT_APP_API}${image.img_url}`, description: image.img_alt }))}
                />
              </div>
            </div>
          </div>
          <div className="location-row">
            <div className="salon">
              <h2>{appointmentData?.newAppointment?.salon_data?.name_al}</h2>
              <p>{appointmentData?.newAppointment?.salon_data?.salon_address?.name_al}, {appointmentData?.newAppointment?.salon_data?.salon_address?.street_address_al}</p>
            </div>
            {console.log("appointmentData", appointmentData)}
            <div className="map-wrapper">
              <GoogleMap
                className="mapAbout"
                location={{ lat: Number(appointmentData?.newAppointment?.salon_data?.salon_address?.latitude), lng: Number(appointmentData?.newAppointment?.salon_data?.salon_address?.longitude) }}
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card" data-aos="flip-right">
            <div className="check"></div>
            <div className="text">
              <h5>{appointmentData?.newAppointment?.status == 5 ? "Termini juaj është në pritje!" : "Termini u caktu me sukses!"}</h5>
              <p>ref. T1067652368</p>
            </div>

            <div className="text">
              <h5>Çfarë ndodh tani?</h5>
              <p>
                {appointmentData?.newAppointment?.status == 5 ? `Nëse asgjë nuk ndryshon, gjithçka që
duhet të bëni është të prisni
konfirmimin nga salloni.` :
                  ` Nëse diçka nuk ndryshon, gjithçka që duhet të bëni është të paraqiteni, në ditën e duhur, në kohën e duhur. Të gjitha detajet e takimit tuaj janë përcjellë në Klinikën e Bukurisë dhe Laserit Aurora, kështu që nuk keni nevojë të printoni një kopje të emailit tuaj të konfirmimit të terminit.`}
              </p>
            </div>

            <div>
              <h5>Keni nevojë për ndihmë?</h5>
              <p>
                {appointmentData?.newAppointment?.status == 5 ? `Për çdo pyetje në lidhje me terminin
tuaj, ju sugjerojmë të kontaktoni direkt
sallonin përkatës. Nëse nuk arrini të
kontaktoni sallonin, ne jemi këtu për të
ju ndihmuar.`:
                  `Për pyetje në lidhje me takimin tuaj, është më mirë të pyesni ekspertët në sallonin ose banjën tuaj. Nëse nuk mund t'i kapni ato, ne jemi gjithmonë të lumtur t'ju ndihmojmë.`}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="checkout-affirmation">
        <Link to={`/salon/${appointmentData?.newAppointment?.salon_data?.salon_address?.id}`}>
          <div className="inline" data-aos="fade-right" data-aos-delay="100">
            <div className="back"></div>
            <p>Kthehu te {appointmentData?.newAppointment?.salon_data?.name_al} - {appointmentData?.newAppointment?.salon_data?.salon_address?.name_al} </p>
          </div>
        </Link>

        <Link to='/'>
          <div className="inline" data-aos="fade-right" data-aos-delay="200">
            <div className="back"></div>
            <p>Kthehu te faqja e parë</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Checkout3;
