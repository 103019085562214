import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="container mt-4 mb-2">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                    <h2 className='text-center '>Termat dhe Kushtet</h2>
                    <p className='mx-auto text-justify'>
                        Termat dhe kushtet për përdorimin e shërbimeve të "Termini" bazohen dhe plotësohen në përputhje me ligjet dhe rregulloret e zbatuara. Në rast të ndonjë mosmarrëveshje midis versionit në gjuhën angleze dhe kopjeve të tjera të përkthyera, versioni anglisht do të jetë i prerë dhe i vlefshëm.
                    </p>

                    <h4 className='fw-bolder mt-3 '>Përmbledhje:</h4>
                    <p className='mb-3'>
                        Ju lutemi të lexoni kujdesshëm kushtet dhe termat për përdoruesit e "Termini" para se të përdorni shërbimet tona.
                    </p>

                    {/* Shto më shumë seksione sipas nevojave tuaja */}

                    <ol style={{ listStyle: "auto !important" }}>
                        <li>
                            1) Nuk marrim përgjegjësi për materiale të ndara nga përdoruesit në "Termini", pasi nuk monitorojmë çdo veprim të përdoruesit. Nëse materialet ose profili juaj është cënuar, ju lutemi të na kontaktoni.
                        </li>
                        <li>
                            2) Publikimi i materialeve që i përkasin dikujt tjetër dhe shkaktojnë shqetësime ligjore nuk është përgjegjësia e "Termini". Duke marrë parasysh se ju keni publikuar materialet, ju mbani përgjegjësi të plotë për përmbajtjen që postoni.
                        </li>
                        <li>
                            3) Nëse keni shqetësime në lidhje me përdorimin e të dhënave tuaja nga "Termini", ju lutemi të lexoni seksionin e Politikave të Privatësisë për përgjigjet e nevojshme rreth përdorimit të të dhënave tuaja personale. Kjo pjesë e përgjegjësisë nënvizon përgjegjësinë e përdoruesit në shpërndarjen dhe mbrojtjen e të dhënave të tyre personale dhe përmbajtjes së postuar.
                        </li>

                    </ol>




                    {/* Shto më shumë seksione sipas nevojave tuaja */}

                    <h4 className='fw-bolder mt-3'>E Drejta e Plotë Juridike</h4>
                    <p>
                        Ju keni të drejtën e plotë juridike për të përdorur shërbimet e "Termini", një platformë online e dizajnuar për digjitalizimin e salloneve të bukurisë për të ofruar një eksperiencë më efikase dhe të personalizuar për ju dhe bizneset e bukurisë. Me një angazhim të vazhdueshëm, "Termini" ka përqendruar përpjekjet tona në përfitimin tuaj, duke krijuar një mjedis online që plotëson nevojat tuaja dhe rrit vlerën e shërbimeve për klientët dhe bizneset e bukurisë.
                    </p>

                    <p className='mt-3'>
                        Një marrëveshje e ndërsjellë është në fuqi midis jush si përdorues dhe "Termini", me seli në Rr.____________ dhe përfshin uebfaqen e tyre në www.termini.beauty.
                    </p>

                    <p className='mt-3'>
                        Termat dhe kushtet aplikohen gjatë çdo përdorimi të shërbimeve të "Termini", kështu që ju këshillojmë të lexoni ato me kujdes para se të vendosni të bëheni pjesë e komunitetit të “Termini”. Ju keni përgjegjësinë për të njohur dhe pranuar kushtet para se të vazhdoni me përdorimin e platformës sonë.
                    </p>

                    {/* Shto më shumë seksione sipas nevojave tuaja */}

                    {/* <h4 className='fw-bolder mt-3'>3. Materialet që Ju Lejohen të Postoni</h4>
                    <p>
                        Ju keni lirinë të postoni çdo material që prezanton më së miri veprimtarinë tuaj, duke përfshirë imazhe, mesazhe, etj., në "Termini". Megjithatë, për të siguruar një ambient të respektueshëm dhe profesional, ju lutemi të respektoni kufizimet e përcaktuara.
                    </p> */}

                    {/* Shto më shumë seksione sipas nevojave tuaja */}
                </div>
                <div className="col-12 col-md-10">
                    <hr className='mb-3 mt-4' />
                    <h2 className='text-center mb-3 '>1. Politikat e privatësisë:</h2>
                    <p className='mx-auto text-justify'>
                        Ju lutemi të mos ndani asnjë informacion personal ose bankar në faqen tuaj të profilit ("Profili") siç janë adresa e shtëpisë, kodet postare, numrat e telefonit, adresat e email, URL, kartat e kreditit/debitit ose informacione të tjera bankare. Nëse vendosni të publikoni informacion personal për veten ose të tjerët, ju do të jeni përgjegjës në rast të shkeljes së privatësisë. Ne ju inkurajojmë të jeni të kujdesshëm kur ndani të dhënat tuaja personale me palët e treta në internet.
                    </p>

                    <p className='mt-3'>
                        Ju mund të përdorni informacionin e ndarë nga përdorues të tjerë në shërbimet e "Termini" nëse veprimi është në përputhje me termat dhe kushtet e përshkruara më lart. Megjithatë, ju nuk mund të përdorni të dhënat e dikujt tjetër për qëllime komerciale, për të dëmtuar, ngacmuar ose për të kryer akte të paligjshme. "Termini" ruan të drejtën për të mbyllur llogarinë tuaj nëse abuzoni me detajet dhe materialet tuaja.
                    </p>

                    {/* <p>
                        Kushtet dhe rregullat aplikohen çdo herë që përdorni shërbimet e "Termini", kështu që ju lutemi të lexoni ato me kujdes para se të vendosni të bëheni pjesë e komunitetit të “Termini”.
                    </p> */}
                    <hr />
                    <h4 className='fw-bolder mt-3'>2. Përfundimi i përdorimit të platfomrës ‘Termini’ nga ana jonë</h4>
                    <p>
                        Nëse ne besojmë se ju keni shkelur parimet tona, ne ruajmë të drejtën të çaktivizojmë profilin tuaj në çdo kohë pa ndonjë detyrim ose domosdoshmëri që të ju njoftojmë paraprakisht dhe as t’ju rimbursojmë për ndonjë shërbim të papërdorur. “Termini” ka të drejtë të pezullojë ose çaktivizojë të drejtën tuaj për akses dhe/ose përdorim të shërbimeve të “Termini”.
                    </p>
                    <p className='mt-3'>
                        “Termini” përdor teknologji të avancuar për të identifikuar sjelljen e paligjshme dhe ka të drejtë të bllokojë ose kufizojë ndonjë adresë IP specifike. Në përgjithësi, “Termini” do të përpiqet (nuk është i detyruar) t’ju informojë nëse aksesi dhe përdorimi i shërbimeve në “Termini” është pezulluar ose do të përfundojë. Kjo pjesë thekson autoritetin e "Termini" për të ndërprerë ose pezulluar shërbimet në rast të shkeljes së rregullave ose parimeve të tyre nga përdoruesi.

                    </p>
                    {/* <p>
                        2.	Kufizimi i referencave tregtare dhe reklamave;
                    </p>
                    <p>
                        3.	Mospostimi i detajeve të kontaktit publikisht përmes email/telefonit;
                    </p>
                    <p>
                        4.	Parandalimi i postimit të materialeve që mund të përmbajnë viruse ose programe të dizajnuara për të dëmtuar platformën;
                    </p>
                    <p>
                        5.	Mospromovimi i materialeve që shkelin të drejtat e palëve të treta, përfshirë të drejtat intelektuale dhe private;
                    </p>
                    <p>
                        6.	Mosatribuimi i materialeve një personi tjetër pa pëlqimin e tij/saj.
                    </p>
                    <p>Këto udhëzime janë themelore për krijimin e një mjedisi të sigurt dhe të përshtatshëm për të gjithë përdoruesit në "Termini". Shkelja e këtyre kufizimeve mund të rezultojë në heqjen e materialeve dhe ndëshkimin e përdoruesit.</p> */}

                    <hr />

                    {/* Shto më shumë seksione sipas nevojave tuaja */}
                    {/* Shto më shumë seksione sipas nevojave tuaja */}
                    {/* Shto më shumë seksione sipas nevojave tuaja */}


                    <h4 className='fw-bolder mt-3'>3. Përfundimi i përdorimit të platformës nga ana juaj</h4>
                    <p className='text-justify'>
                        Nëse jeni regjistruar dhe keni përdorur shërbimet e “Termini|”, ju mund ta ndërpresni llogarinë tuaj në çdo kohë, duke shkuar te _________; në “Termini” dhe zgjidhni _______;Fshini llogarinë time _______&_______;. Për arsye të privatësisë, “Termini” do të përfundojë të gjitha llogaritë e fshira, prandaj, nuk mund të rikuperoni llogarinë tuaj në një kohë të mëvonshme. Për detaje të mëtejshme, ju lutemi referojuni Politikës së Privatësisë. Në përgjithësi, shërbimi/et që keni shfrytëzuar në“Termini” nuk do të shfaqet/en si i dukshëm pas përfundimit ose anulimit të llogarisë.
                    </p>

                    <p className='mt-3'>
                        Duke përdorur shërbimet e “Termini”, ju pranoni dhe pajtoheni me termat dhe kushtet e paraqitura më poshtë:
                    </p>

                    {/* Shto më shumë seksione sipas nevojave tuaja */}
                    {/* <hr /> */}
                    <h4 className='fw-bolder mt-3'>A) Përdorimi i Platformës:</h4>
                    <p>
                        Ju lejohet të përdorni platformën "Termini" në përputhje me qëllimin e saj kryesor, për shërbimet dhe nevojat tuaja personale në fushën e bukurisë.
                    </p>
                    <h4 className='fw-bolder mt-3'>B) Informacioni Personal:</h4>
                    <p>
                        “Termini” respekton privatësinë tuaj. Informacioni personal që jepni në platformë përdoret vetëm për qëllime të nevojshme për shërbimet tona dhe është mbrojtur nga masat e sigurisë të përshtatura.
                    </p>
                    <h4 className='fw-bolder mt-3'>C) Përgjegjësia për Fjalëkalimet:</h4>
                    <p>
                        Ju jeni përgjegjës për ruajtjen e sigurisë së fjalëkalimeve dhe të dhënave të llogarisë suaj. Në rast të ndonjë dyshimi për shfrytëzim të paautorizuar, ju lutemi të njoftoni menjëherë "Termini"-n.Kjo pjesë thekson përgjegjësinë tuaj si përdorues për sigurinë dhe parandalimin e hyrjeve të paautorizuara në llogarinë tuaj.
                    </p>
                    <h4 className='fw-bolder mt-3'>D) Lidhjet dhe Konfliktet:</h4>
                    <p>
                        Pakënaqësitë apo anulimet nga ofruesi i shërbimit/sallonet nuk janë përgjegjësi e “Termini”. Çdo mosmarrëveshje e mundshme duhet të trajtohet direkt me sallonin sipas rregullave dhe kushteve të ofruara nga ky i fundit. Kjo tregon se "Termini" nuk merr përgjegjësi për çështje të tilla, dhe bashkëpunimi për zgjidhjen e tyre duhet bërë drejtpërdrejt me ofruesin e shërbimit ose sallonin.
                    </p>
                    <h4 className='fw-bolder mt-3'>E) Detyrimet dhe Përgjegjësitë për Cilësinë e Shërbimeve nga Sallonet</h4>
                    <p>
                        Salloni (ose 'biznesi') është i vetmi përgjegjës për cilësinë e shërbimeve të ofruara. Ju jeni të inkurajuar të komunikoni drejtpërdrejt me sallonin për çdo pyetje apo shqetësim dhe të bëni zgjedhje të informuar. Kjo thekson se "Termini" nuk merr përgjegjësi për cilësinë e shërbimeve dhe inkurajon përdoruesit të kontaktojnë sallonin për çështje specifike.
                        Ju lutemi të lexoni këto terma dhe kushte me kujdes para se të përdorni shërbimet tona në platformën "Termini". Përdorimi i platformës konsiderohet si pranim i këtyre termave dhe kushteve. “Termini” rezervon të drejtën për të ndryshuar ose përditësuar këto kushte në çdo kohë.
                    </p>
                    <h4 className='fw-bolder mt-3'>F) Detyrimet dhe Përgjegjësitë ndaj Vonesave dhe Humbjeve të termineve</h4>
                    <p>
                        Përdoruesi është i vetmi përgjegjës për anulimin/humbjen e termineve. “Termini” nuk mban përgjegjësi mbi këtë veprim. Përdoruesit janë të inkurajuar të komunikojnë drejtpërdrejtë me sallonin për çdo pyetje apo njoftim rreth anulimeve dhe vonesave të termineve. Në rast se përdoruesi vonohet më shumë se koha e caktuar në politikën e anulimeve të sallonit/biznesit përkatës ose nuk shfaqet për një takim të caktuar, "Termini" nuk mban përgjegjësi për humbjen/vonesën e këtij termini apo ndonjë pasojë të mundshme.
                    </p>
                    <h5 className='fw-bolder mt-3'>G) Detyrimet dhe Përgjegjësitë e Sallonit/Biznesit për Anulimet dhe Ri-Planifikimet</h5>
                    <p>
                        Termini mund të anulohet nga ana e sallonit/biznesit për arsye të ndryshme. Në këto raste, përdoruesit do të njoftohen paraprakisht dhe është përgjegjësia e sallonit/biznesit të ofrojë një alternativë ose të riplanifikojë termin. Inkurajimi i këtij komunikimi të drejtëpërdrejtë ndihmon përdoruesit të bëjnë zgjedhje të informuara dhe të sigurohen për cilësinë dhe kënaqësinë e shërbimeve të ofruara nga salloni.
                    </p>
                    <p className='mt-3'>
                        Ju lutemi të lexoni këto terma dhe kushte me kujdes para se të përdorni shërbimet tona në platformën "Termini". Përdorimi i platformës konsiderohet si pranim i këtyre termave dhe kushteve. “Termini” rezervon të drejtën për të ndryshuar ose përditësuar këto kushte në çdo kohë.
                    </p>
                    <p className='fst-italic text-center mt-4'>Përditësuar për herë të fundit më 15 Dhjetor 2023.</p>
                    {/* Shto më shumë seksione sipas nevojave tuaja */}
                </div>



            </div>
        </div>
    );
};

export default TermsAndConditions;
