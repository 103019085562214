import React, { useState, useEffect } from "react";
import {
  BusinessButton,
  LoginButton,
  LogOutButton,
  SocialMediaLinks,
  ProfileButton,
  RegisterButton,
} from "../ui/buttonGroup";
import { Link, useLocation } from "react-router-dom";
import "./header.scss";
import { connect } from "react-redux";
import { setLoggedIn, logout, setUserId, setSelectedLang } from "../../redux/Functions/actions";
import { toast } from "react-toastify";
import { AiOutlineMenu, AiOutlineHome } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { GiHairStrands, GiFingernail, GiFemaleLegs } from 'react-icons/gi'
import { MdKeyboardArrowDown, MdMoreHoriz, MdFaceRetouchingNatural } from "react-icons/md";
import axios from '../../axios';
import Cookies from "js-cookie";
import ResetPasswordModal from '../ui/resetPassword'
import { Avatar } from "@mui/material";
// import TranslateLabel from "../translations/labels";

const Header = (props) => {

  function TranslateLabel(key) {
    const langData = props.lang_data;
    const selected_lang = props.selected_lang;

    if (selected_lang === 'AL') {
      let translate = langData?.filter(el => el.key === key)[0]?.label_al;
      return translate
    }
    else {
      let translate = langData?.filter(el => el.key === key)[0]?.label_en;
      return translate
    }


  }

  const location = useLocation();
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith("/reset-password/")) {
      setShowResetPasswordModal(true);
    } else {
      setShowResetPasswordModal(false);
    }
  }, [location]);
  const handleRedirect = (queryType) => {
    const url = `/searchResult/searchData/all?treatment=${queryType}`;
    setDropdown(false);
    closeDrawer();
    window.location.assign(url);
  };


  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const [autoLogoutTimer, setAutoLogoutTimer] = useState(null);
  const [moreOptions, setMoreOptions] = useState([]);

  const handleLogout = (e) => {
    e.preventDefault();
    Cookies.remove('termini_im');
    window.localStorage.clear();
    // window.location.assign('/');
    toast.success("Jeni shkyqur me sukses!");
    props.logout();
    clearTimeout(autoLogoutTimer);
    props.setUserId(null)


  };

  useEffect(() => {

    clearTimeout(autoLogoutTimer);

    if (props.logged_in) {
      const timer = setTimeout(() => {
        handleLogout(new MouseEvent("click"));
      }, 86400000); // 24 hour, test for 30000 => 30sec worked
      setAutoLogoutTimer(timer);
    }
  }, [props.logged_in]);


  const showDrawer = () => {
    setOpenSideDrawer(!openSideDrawer);
  };


  const backdropClickHandler = () => {
    setOpenSideDrawer(false);
  };

  const closeDrawer = () => {
    setOpenSideDrawer(false);
  };

  const showDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleLinkClick = () => {
    setDropdown(false);
    closeDrawer();
  };

  let sideDrawer;
  let backdrop;
  function handleGetServices() {
    axios.get("/api/client/services/all_ser")
      .then(res => setMoreOptions(res.data))
      .catch(err => console.log('errorGetting services', err))
  }
  useEffect(() => { handleGetServices() }, [])


  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#white';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }
  function stringAvatar(name) {
    if (!name || !name.includes(' ')) {
      return {
        sx: {
          bgcolor: '#white',
        },
        children: name,
      };
    }

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  if (openSideDrawer) {
    sideDrawer = (
      <div className="mobile-sidebar">
        <div className="sidebar-logo-row">
          <Link to="/">
            <div className="mobile-logo"></div>
          </Link>
          <button onClick={closeDrawer}>
            <CgClose />
          </button>
        </div>

        <div className="mobile-header-links">
          <div className="header-sidebar-link">
            <Link to="/">
              {/* <AiOutlineHome /> */}
              {TranslateLabel("Ballina")}
            </Link>
          </div>
          {moreOptions?.slice(0, 5)?.map(elm =>
            <div className="header-sidebar-link">
              <Link onClick={() => handleRedirect(`${elm?.name_al}`)}>
                {props?.selected_lang === "AL" ? elm?.name_al : elm?.name_en}</Link>
            </div>
          )}
          {/* <div className="header-sidebar-link">
            <Link onClick={() => handleRedirect("flok")}><GiHairStrands /> Flokë</Link>
          </div>
          <div className="header-sidebar-link">
            <Link onClick={() => handleRedirect("thonj")}><GiFingernail />Thonj</Link>
          </div>
          <div className="header-sidebar-link">
            <Link onClick={() => handleRedirect("heqje me dyll")}><GiFemaleLegs />Depilim</Link>
          </div>
          <div className="header-sidebar-link">
            <Link onClick={() => handleRedirect("fytyr")}><MdFaceRetouchingNatural />Fytyrë</Link>
          </div> */}
          <div className="sidebar-dropwdown">
            <div className="header-sidebar-link" onClick={showDropdown}>
              <Link to="/" className="header-anchor">
                {/* <MdMoreHoriz /> */}
                {TranslateLabel("MëShumë")}
              </Link>
              <div className="dropdown-arrow">
                <MdKeyboardArrowDown />
              </div>
            </div>
            {dropdown === true && (
              <div className="dropdown-links">
                {moreOptions?.slice(5)?.map(elm =>

                  <Link onClick={() => handleRedirect(elm?.name_al)} >
                    {props?.selected_lang === "AL" ? elm?.name_al : elm?.name_en}
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="mobile-header-buttons row">
          <div
            className="col-12"
          // style={{ gap: "20px" }}
          >
            {!props?.logged_in ? <><LoginButton /> <RegisterButton /></> : <ProfileButton />}
            {!props?.logged_in ? (
              <BusinessButton />
            ) : (
              <div className="pt-3">

                <LogOutButton onClick={handleLogout} />
              </div>
            )}
          </div>
          <div className="col-12 pt-2 d-flex justify-content-initial">

            {/* <SocialMediaLinks /> */}
            <div class={props?.sidebar === "collapsed" ? " dropdown w-50" : " dropdown"} >
              <button type="button" data-bs-toggle="dropdown" aria-expanded="false" id="languageDropdown" class={props?.sidebar === "collapsed" ? "mt-4 dropdown btn border dropdown-toggle w-50" : " w-100 dropdown btn border dropdown-toggle w-75"}>
                {props?.sidebar === "collapsed" ? (
                  <img src={props?.selected_lang === 'AL' ? "https://flagpedia.net/data/flags/w702/al.webp" : "https://flagpedia.net/data/flags/w702/gb.webp"} alt={props?.selected_lang === 'AL' ? 'Albania Flag' : 'English Flag'} style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '5px', borderRadius: '3px' }} />
                ) : (
                  props?.selected_lang ?
                    <span>
                      <img src={props?.selected_lang === 'AL' ? "https://flagpedia.net/data/flags/w702/al.webp" : "https://flagpedia.net/data/flags/w702/gb.webp"} alt={props?.selected_lang === 'AL' ? 'Albania Flag' : 'English Flag'} style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '5px', borderRadius: '3px' }} />
                      {props?.selected_lang === 'AL' ? 'SHQ' : 'ENG'}
                    </span>
                    : 'Choose Language'
                )}
              </button>
              <ul class={`border-0 select_one_language dropdown-menu p-0  ${props?.sidebar === "collapsed" ? "w-50" : "w-75"}`} aria-labelledby="languageDropdown">
                <li>
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="dropdown-item rounded-top" role="button" onClick={() => props?.setSelectedLang('EN')}>
                        <img src={"https://flagpedia.net/data/flags/w702/gb.webp"} alt="English Flag" style={{ maxWidth: '25px', maxHeight: '35px', marginRight: '5px', borderRadius: '3px' }} />
                        {props?.sidebar !== "collapsed" && "ENG"}
                      </div>
                      <div class="dropdown-item rounded-bottom" role="button" onClick={() => props?.setSelectedLang('AL')}>
                        <img src={"https://flagpedia.net/data/flags/w702/al.webp"} alt="Albanian Flag" style={{ maxWidth: '25px', maxHeight: '20px', marginRight: '5px', borderRadius: '3px' }} />
                        {props?.sidebar !== "collapsed" && "SHQ"}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    );

    backdrop = (
      <div className="header-backdrop" onClick={backdropClickHandler}></div>
    );
  }

  return (
    <div className="header-layout">
      {showResetPasswordModal && <ResetPasswordModal closeModal={() => setShowResetPasswordModal(false)} />}

      <div className="row d-flex align-items-center">
        <div className="col-lg-6 col-md-6 header-logo">
          <Link to="/" className="logo-container">
            <div className="logo"></div>
          </Link>
        </div>
        <div className="col-lg-6 col-md-6 d-flex buttons">
          <div className="d-flex align-items-center ms-auto">
            {/* <SocialMediaLinks /> */}
            {!props.logged_in ? <><LoginButton /> <RegisterButton /></> : <ProfileButton />}
            {!props?.logged_in ? (
              <BusinessButton />
            ) : (
              <LogOutButton onClick={handleLogout} />
            )}
          </div>
        </div>
      </div>
      <div className="header-links d-flex justify-content-between ms-3 mt-2">
        <div className="costum-header-links d-flex">

          <div className="home-link my-auto">
            <Link to="/">{TranslateLabel("Ballina")}</Link>
          </div>
          {moreOptions?.slice(0, 4)?.map(elm =>
            <div className="header-sidebar-link my-auto">
              <Link onClick={() => handleRedirect(elm?.name_al)}> {props?.selected_lang === "AL" ? elm?.name_al : elm?.name_en}</Link>
            </div>
          )}
          <div className="more-link position-relative my-auto">
            <p className="costumHeaderButton">{TranslateLabel("MëShumë")}</p>
            <div className="header-dropdown">
              <div className="inner-header-dropdown">
                {moreOptions?.length > 4 &&
                  moreOptions.slice(4)?.map(el =>
                    <Link onClick={() => handleRedirect(el?.name_al)}>{props?.selected_lang === "AL" ? el?.name_al : el?.name_en}</Link>
                  )
                }
              </div>
            </div>
          </div>
        </div>

        <div class={props?.sidebar === "collapsed" ? " dropdown w-50" : " dropdown"} >
          <button type="button" data-bs-toggle="dropdown" aria-expanded="false" id="languageDropdown" class={props?.sidebar === "collapsed" ? "mt-4 dropdown btn border dropdown-toggle w-50" : " w-100 dropdown btn border dropdown-toggle w-75"}>
            {props?.sidebar === "collapsed" ? (
              <img src={props?.selected_lang === 'AL' ? "https://flagpedia.net/data/flags/w702/al.webp" : "https://flagpedia.net/data/flags/w702/gb.webp"} alt={props?.selected_lang === 'AL' ? 'Albania Flag' : 'English Flag'} style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '5px', borderRadius: '3px' }} />
            ) : (
              props?.selected_lang ?
                <span>
                  <img src={props?.selected_lang === 'AL' ? "https://flagpedia.net/data/flags/w702/al.webp" : "https://flagpedia.net/data/flags/w702/gb.webp"} alt={props?.selected_lang === 'AL' ? 'Albania Flag' : 'English Flag'} style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '5px', borderRadius: '3px' }} />
                  {props?.selected_lang === 'AL' ? 'SHQ' : 'ENG'}
                </span>
                : 'Choose Language'
            )}
          </button>
          <ul class={`border-0 select_one_language dropdown-menu p-0  ${props?.sidebar === "collapsed" ? "w-50" : "w-75"}`} aria-labelledby="languageDropdown">
            <li>
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="dropdown-item rounded-top" role="button" onClick={() => props?.setSelectedLang('EN')}>
                    <img src={"https://flagpedia.net/data/flags/w702/gb.webp"} alt="English Flag" style={{ maxWidth: '25px', maxHeight: '35px', marginRight: '5px', borderRadius: '3px' }} />
                    {props?.sidebar !== "collapsed" && "ENG"}
                  </div>
                  <div class="dropdown-item rounded-bottom" role="button" onClick={() => props?.setSelectedLang('AL')}>
                    <img src={"https://flagpedia.net/data/flags/w702/al.webp"} alt="Albanian Flag" style={{ maxWidth: '25px', maxHeight: '20px', marginRight: '5px', borderRadius: '3px' }} />
                    {props?.sidebar !== "collapsed" && "SHQ"}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

      </div>
      <div className="mobile-header">
        <div className="mobile-toolbar">
          <div className="mobile-logo-row">
            <Link to="/">
              <div className="mobile-logo"></div>
            </Link>
          </div>
          <button onClick={showDrawer}>
            <AiOutlineMenu />
          </button>
        </div>
        {sideDrawer}
        {backdrop}
      </div>
    </div >
  );
};


// In order to get data from Redux, you need to call their value from the store of Redux by importing it and by using connect from Redux, we pass all of that data that we imported as props to our component or page!
const mapStateToProps = (state) => ({
  selected_lang: state.data.selected_lang,
  lang_data: state?.data?.lang_data,
  logged_in: state.data.logged_in,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    setUserId: (data) => dispatch(setUserId(data)),
    logout: () => dispatch(logout()),
    setSelectedLang: (data) => dispatch(setSelectedLang(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
