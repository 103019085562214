import React, { useEffect, useRef, useState } from "react";
import "./userProfile.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { AiOutlineMinus } from "react-icons/ai";
import tableData from "../../data/tableData.json";
import { connect } from "react-redux";
import axios from "../../axios";
import { setUserId } from "../../redux/Functions/actions";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import sign from "jwt-encode";
import PasswordStrengthBar from "react-password-strength-bar";

const UserProfile = (props) => {
  const [userProfile, setUserProfile] = useState([]);
  const [userData, setUserData] = useState();
  const [disabled, setDisabled] = useState(false);
  const [disabledPass, setDisabledPass] = useState(false);
  const [passwordChange, setPasswordChange] = useState();
  const [isTyping, setIsTyping] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const [show, setShow] = useState(false);

  const hidePassword = () => {
    setShow(!show);
  };
  const name = (e) => {
    const ud = Object.assign({}, userData);
    ud.name = e.target.value;
    setUserData(ud);
    setDisabled(true);
  };
  const surname = (e) => {
    const ud = Object.assign({}, userData);
    ud.surname = e.target.value;
    setUserData(ud);
    setDisabled(true);
  };

  const phone_number = (e) => {
    const ud = Object.assign({}, userData);
    ud.phone_number = e.target.value;
    setUserData(ud);
    setDisabled(true);
  };

  const password = (e) => {
    const ud = Object.assign({}, userData);
    ud.pass = e.target.value;
    setUserData(ud);
    setDisabledPass(true);
  };
  const getUserData = () => {
    axios
      .get(`/api/client/users/single/${props?.user_id}`)
      .then((data) => {
        setUserProfile(data.data);
      })
      .catch((err) => {
        console.log("userProfileGetErr", err);
      });
  };
  const [userAppointments, setUserAppointments] = useState();
  const [
    overSubscriptionLimitAppointments,
    setOverSubscriptionLimitAppointments,
  ] = useState();

  const getUserAppointments = () => {
    axios
      .get(`/api/client/appointments/singleUser/${props?.user_id}`)
      .then((data) => {
        setUserAppointments(
          data.data.filter((el) => el.over_sub_limit === false)
        );
        setOverSubscriptionLimitAppointments(
          data.data.filter((el) => el.over_sub_limit === true)
        );
      })
      .catch((err) => {
        console.log("userProfileGetErr", err);
      });
  };
  const changeAppointmentStatus = (appID, status) => {
    axios
      .put(`/api/client/appointments/change_status/${appID}`, { status_id: status })
      .then((data) => {
        toast.success("Keni anuluar terminin tuaj.")
        getUserAppointments();
      })
      .catch((err) => {
        toast.error("Ndodhi një gabim gjatë anulimit të terminit!")
        console.log("changeAppointmentStatus", err);
      });
  };

  const groupedData = [];
  overSubscriptionLimitAppointments?.forEach((item, index) => {
    const salonName = item?.salons[0]?.name_al;

    if (!groupedData[salonName]) {
      groupedData[salonName] = {
        salon_name: salonName,
        appointment_length: 0,
      };
    }

    groupedData[salonName].appointment_length++;
  });

  const groupedArray = Object.values(groupedData);
  const submitData = (e) => {
    e.preventDefault();
    let user = {
      id: props?.user_id,
      name: userData?.name,
      surname: userData?.surname,
      phone_number: userData?.phone_number,
    };

    axios
      .put(`/api/client/users/update`, user)
      .then((res) => {
        setDisabled(false);
        toast.success("Të dhënat u përditsuan me sukses!");
      })
      .catch((err) => {
        toast.error("Ndodhi një gabim gjatë ndryshimit të të dhënave!");
        console.log("axiosErr", err);
      });
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    let newpass = e?.target?.newPassword.value;
    let oldpass = e?.target?.passwordOld.value;
    let repetPassword = e?.target?.confirmPass.value;
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const signNewPass = sign(newpass, secretKey);
    const signOldPass = sign(oldpass, secretKey);

    if (newpass != repetPassword) {
      toast.error("Fjaleklimet nuk perputhen");
    } else if (oldpass == newpass) {
      toast.error("Password  nuk mund te jete i njenjt me te vjetrin");
    } else if (newpass == repetPassword) {
      const passwordat = {
        updated_by_uid: props?.user_id,
        email: userProfile?.email,
        old_password: signOldPass,
        new_password: signNewPass,
      };
      axios
        .put("/api/client/users/change_pass", passwordat)
        .then((res) => {
          toast.success(res?.data?.msg);
          if (res?.data?.error) {
            toast.error(res?.data?.error);
          }
        })
        .catch((err) => {
          toast.error(err?.error);
          console.log("respass 1 err", err);
        });

      // setPasswordChange(passwordat);
    }
  };

  const handlePasswordChange = (e) => {
    setIsTyping(true);
    const newPassword = e.target.value;
    setUserData((prevState) => ({
      ...prevState,
      pass: newPassword,
    }));
    setPasswordStrength(calculatePasswordStrength(newPassword));
  };

  const calculatePasswordStrength = (password) => {
    const lengthScore = Math.min(100, password.length * 10);
    const uppercaseScore = /[A-Z]/.test(password) ? 25 : 0;
    const lowercaseScore = /[a-z]/.test(password) ? 25 : 0;
    const numberScore = /\d/.test(password) ? 25 : 0;
    const specialCharScore = /[^A-Za-z0-9]/.test(password) ? 25 : 0;

    const mediumPasswordRequirements = {
      minLength: 8, // 8 or 10
      hasUppercaseOrNumber: /[A-Z\d]/.test(password),
    };

    const strongPasswordRequirements = {
      minLength: 10,
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialChar: /[^A-Za-z0-9]/.test(password),
    };

    if (
      password.length >= strongPasswordRequirements.minLength &&
      strongPasswordRequirements.hasUppercase &&
      strongPasswordRequirements.hasLowercase &&
      strongPasswordRequirements.hasNumber &&
      strongPasswordRequirements.hasSpecialChar
    ) {
      return 100; // Strong password
    } else if (
      password.length >= mediumPasswordRequirements.minLength &&
      mediumPasswordRequirements.hasUppercaseOrNumber
    ) {
      return 70; // Medium password
    }

    return (
      lengthScore +
      uppercaseScore +
      lowercaseScore +
      numberScore +
      specialCharScore
    );
  };

  let mappedAppointments = [];
  if (overSubscriptionLimitAppointments) {
    const groupedAppointments = overSubscriptionLimitAppointments?.reduce(
      (result, appointment) => {
        const salonId = appointment?.salon_id;
        if (!result[salonId]) {
          result[salonId] = [];
        }
        result[salonId]?.push(appointment);
        return result;
      },
      {}
    );

    // Mapping the groupedAppointments to show salon.name_al
    mappedAppointments = Object?.keys(groupedAppointments)?.map(
      async (salonId) => {
        const salonAppointments = await groupedAppointments[salonId];
        const salonName = await salonAppointments[0]?.salon?.name_al; // Assuming all appointments within a salon have the same name_al
        return {
          salonName,
          appointments: salonAppointments,
        };
      }
    );
  }

  useEffect(() => {
    getUserData();
    getUserAppointments();
  }, []);
  const [newPicData, setNewPicData] = useState();

  function handleFileUpload(selectedFile) {
    // console.log("selectedFile", selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const blobData = e.target.result;
        const imgElement = document.getElementById("selectedImage");
        imgElement.src = blobData;
      };

      reader.readAsDataURL(selectedFile);
    } else {
      console.log("No file selected");
    }
  }

  // function handleFileUpload(input) {
  //   if (input.files && input.files[0]) {
  //     const reader = new FileReader();

  //     reader.onload = function (e) {
  //       const profilePic = document.querySelector('.profile-pic');
  //       profilePic.setAttribute('src', e.target.result);
  //     };

  //     reader.readAsDataURL(input.files[0]);
  //   }
  // }

  // document?.querySelector('.upload-button')?.addEventListener('click', function () {
  //   document.querySelector('.file-upload').click();
  // });

  function handleImageUpload() {
    const formData = new FormData();
    formData.append("id", userProfile?.id);
    formData.append("pic_url", newPicData);
    axios
      .put("/api/client/users/update", formData)
      .then((res) => {
        // console.log('image sucess', res)
        toast.success("Imazhi u ngarkua me sukses!");
      })
      .catch((err) => console.log("Error ocured while uploading image!"));
  }
  return (
    <div className="user-profile-layout">
      <Tabs>
        <div className="profile-sidebar">
          <h1 className="tracking-in-expand">Profili Im</h1>
          <TabList>
            <Tab>
              <h5>
                <AiOutlineMinus />
                Profili
              </h5>
            </Tab>
            <Tab>
              <h5>
                <AiOutlineMinus />
                Terminet
              </h5>
            </Tab>
            {/* <Tab>
              <h5>
                <AiOutlineMinus />
                Cilësimet
              </h5>
            </Tab> */}
          </TabList>
        </div>

        <div className="profile-content">
          <TabPanel>
            <div className="user-info-container">
              <div className="user-info row">
                <div className="user-img-container col-xl-4 col-lg-12 col-md-12 col-12 text-center">
                  <div className="user-img ">
                    <div className="inner-img ">
                      <div class="row">
                        <div class=" medium-3 large-3 columns">
                          <div class="circle mx-auto">
                            {!userProfile?.pic_url ? (
                              !newPicData ? (
                                <img
                                  className="profile-pic"
                                  src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                                />
                              ) : (
                                <img
                                  id="selectedImage"
                                  src=""
                                  alt="Selected Image"
                                />
                              )
                            ) : (
                              <img
                                className="col-12"
                                id="selectedImage"
                                src={`${process.env.REACT_APP_API}${userProfile?.pic_url}`}
                              />
                            )}
                          </div>
                          <div class="p-image">
                            <i
                              class="fa fa-camera upload-button"
                              onClick={() => {
                                document
                                  ?.querySelector(".file-upload")
                                  ?.click();
                              }}
                            ></i>
                            <input
                              class="file-upload"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                setNewPicData(e.target.files[0]);
                                handleFileUpload(e.target.files[0]);
                              }}
                            />
                          </div>
                          {/* <a role="button" onClick={() => handleImageUpload()} className="edit_profile_image" >Ruaj ndryshimet</a> */}
                        </div>
                        <a
                          role="button"
                          onClick={() => handleImageUpload()}
                          className="edit_profile_image"
                        >
                          Ruaj foton
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="main-info col-xl-5 col-lg-8 col-md-7 col-12">
                  <h2 className="tracking-in-expand">
                    {userProfile?.name} {userProfile?.surname}
                  </h2>

                  <div className="info-wrapper">
                    <div className="info-item">
                      <h6>Emaili:</h6>
                      <p>{userProfile?.email}</p>
                    </div>

                    <div className="info-item">
                      <h6>Telefoni:</h6>
                      <p>{userProfile?.phone_number}</p>
                    </div>

                    {/* <div className="info-item">
                      <h6>Address:</h6>
                      <p> 123 Main St, City, State</p>
                    </div> */}

                    <div className="info-item">
                      <h6>Gjinia:</h6>
                      <p>{userProfile?.gender === 1 ? "Mashkull" : "Femer"}</p>
                    </div>

                    {/* <div className="info-item">
                      <h6>Age:</h6>
                      <p>23</p>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <h2 className="tracking-in-expand">Përditësoni profilin tuaj</h2> */}
              <div className="settings-container">
                <form onSubmit={submitData} className="first-form">
                  <div className="input-wrapper">
                    <p>Emri: </p>
                    <input
                      type="text"
                      placeholder="Emri"
                      defaultValue={userProfile?.name}
                      onChange={name}
                    />
                  </div>
                  <div className="input-wrapper">
                    <p>Mbiemri: </p>
                    <input
                      type="text"
                      placeholder="Mbiemri"
                      defaultValue={userProfile?.surname}
                      onChange={surname}
                    />
                  </div>
                  <div className="input-wrapper">
                    <p>Telefoni: </p>
                    <input
                      type="tel"
                      placeholder="Telefoni"
                      defaultValue={userProfile?.phone_number}
                      onChange={phone_number}
                    />
                  </div>
                  <div className="input-wrapper">
                    <p>Emaili:</p>
                    <input
                      type="email"
                      placeholder="Emaili"
                      defaultValue={userProfile?.email}
                      disabled
                    />
                  </div>
                  <button
                    type="submit"
                    className="update-btn"
                    disabled={!disabled}
                  >
                    Ruaj ndryshimet
                  </button>
                </form>
                <form onSubmit={handleChangePassword} className="second-form">
                  <div className="input-wrapper">
                    <p>Fjalëkalimi juaj aktual:</p>
                    <input
                      type={!show ? "password" : "text"}
                      name="passwordOld"
                      placeholder="Fjalëkalimi aktual"
                      onChange={password}
                      id="passwordOld"
                    />
                    <span
                      toggle="#password-field"
                      className={
                        show
                          ? "fa fa-fw fa-eye field-icon toggle-password"
                          : "fa fa-fw fa-eye-slash field-icon toggle-password"
                      }
                      onClick={hidePassword}
                    />
                  </div>
                  <div className="input-wrapper">
                    <p>Fjalëkalimi juaj i ri:</p>
                    <input
                      placeholder="Fjalëkalimi i ri"
                      name="newPassword"
                      id="newPassword"
                      type={!show ? "password" : "text"}
                      onChange={handlePasswordChange}
                    />
                    <span
                      toggle="#password-field"
                      className={
                        show
                          ? "fa fa-fw fa-eye field-icon toggle-password"
                          : "fa fa-fw fa-eye-slash field-icon toggle-password"
                      }
                      onClick={hidePassword}
                    />
                    {isTyping && (
                      <PasswordStrengthBar
                        password={userData?.pass}
                        scoreWords={["Weak", "Medium", "Strong"]}
                        scoreWordStyle={(score) => {
                          switch (score) {
                            case 1:
                              return { color: "red" };
                            case 2:
                              return { color: "orange" };
                            case 3:
                              return { color: "green" };
                            default:
                              return { color: "gray" };
                          }
                        }}
                      />
                    )}
                  </div>
                  <div className="input-wrapper">
                    <p>Konfirmo fjalëkalimin tuaj të ri:</p>
                    <input
                      type={!show ? "password" : "text"}
                      placeholder="Fjalëkalimi i ri"
                      name="confirmPass"
                      id="confirmPass"
                    />
                    <span
                      toggle="#password-field"
                      className={
                        show
                          ? "fa fa-fw fa-eye field-icon toggle-password"
                          : "fa fa-fw fa-eye-slash field-icon toggle-password"
                      }
                      onClick={hidePassword}
                    />
                  </div>
                  <button
                    type="submit"
                    className="update-btn"
                    disabled={!disabledPass}
                  >
                    Ndrysho fjalëkalimin{" "}
                  </button>
                </form>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="appointments-container">
              <h2 className="tracking-in-expand">Terminet </h2>
              {overSubscriptionLimitAppointments?.length > 0 ? (
                <h3>
                  {groupedArray?.length < 1
                    ? "Ju keni" +
                    groupedArray?.map(
                      (el) =>
                        el?.appointment_length +
                        ` që duhet të verifikohen nga pronari i ${el?.salon_name}!`
                    )
                    : `Ju keni  ${groupedArray?.map(
                      (el) =>
                        ` ${el?.appointment_length} termin që duhet të ${el?.appointment_length > 1
                          ? "verifikohen"
                          : "verifikohet"
                        } nga pronari ${el?.salon_name} `
                    )} `}
                </h3>
              ) : (
                ""
              )}
              <div className="table-container">
                <div className="profile-table">
                  <div className="titles">
                    <div className="title">Tretmani</div>
                    <div className="title">Salloni</div>
                    <div className="title">Data</div>
                    <div className="title">Ora</div>
                    <div className="title">Statusi</div>
                  </div>
                  <div className="data-container">
                    {userAppointments?.map((data, index) => {
                      return (
                        <div className="data-wrapper" key={index}>
                          <div className="data treatment-name">
                            {data?.salon_sub_service?.name_al
                              ? data?.salon_sub_service?.name_al
                              : data?.salon_sub_services[0]?.name_al}
                          </div>
                          <div className="data salon-name">
                            {data?.salons[0]?.name_al}
                          </div>
                          <div className="data appointment-date">
                            {data.date_of_appointment}
                          </div>
                          <div className="data appointment-time">
                            {data.app_start}
                          </div>
                          <div
                            className={`data appointment-status ${data?.status === 1
                              ? "aktiv"
                              : data.status === 2
                                ? "anuluar"
                                : data.status === 3
                                  ? "përfunduar"

                                  : "planifikuar"
                              }`}
                          >
                            <div class={"dropdown"} >
                              <button disabled={data.status === 2} type="button" data-bs-toggle="dropdown" aria-expanded="false" id="languageDropdown" className="dropdown btn border dropdown-toggle" style={{ color: `${data.status === 2 ? "red" : "#212529"}` }}>

                                {data?.status === 1
                                  ? "Aktiv"
                                  : data.status === 2
                                    ? "Anuluar"
                                    : data.status === 3
                                      ? "Përfunduar"
                                      : data.status === 5
                                        ? "Në pritje"
                                        : "Planifikuar"}
                              </button>
                              <ul class={` border-0 select_one_language dropdown-menu p-0 w-100`} aria-labelledby="languageDropdown">
                                <li>
                                  <div class="row align-items-center">
                                    <div class="col-md-12">
                                      <div class="dropdown-item rounded-bottom" role="button" onClick={() => changeAppointmentStatus(data?.id, 2)} style={{ color: "red" }}>
                                        Anulo
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>

                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          {/* <TabPanel>
          
          </TabPanel> */}
        </div>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user_id: state.data.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserId: (data) => dispatch(setUserId(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
